import ActiveEncuesta from './Components/Active/Encuesta';
import Zonas from './Components/Administracion/Zonas/Zonas';
import Puntos from './Components/Administracion/Puntos/Puntos';
import Videos from './Components/Administracion/Videos/videos';
import ReportePA from './Components/Control/ReportePA/ReportePA';
import DataCruda from './Components/Control/DataCruda/DataCruda';
import Ciudades from './Components/Administracion/Ciudades/Ciudades';
import LandingPage from './Components/MainFrame/Landing/LandingPage';
import Periodos from './Components/Administracion/Periodos/Periodos';
import UserControl from './Components/Control/UserControl/UserControl';
import ReportShop from './Components/ClientSide/ReportShop/ReportShop';
import LoginScreen from './Components/MainFrame/LoginPage/LoginScreen';
import Preguntas from './Components/Administracion/Preguntas/Preguntas';
import Secciones from './Components/Administracion/Secciones/Secciones';
import Encuestas from './Components/Administracion/Encuestas/Encuestas';
import AdminDashboard from './Components/Administracion/Dashboard/Dashboard';
import ListarVideos from './Components/ClientSide/UploadVideos/ListarVideos';
import Metodologia from './Components/Administracion/Metodologia/Metodologia';
import ReportSheet from './Components/Administracion/HojaReporte/ReportSheet';
import EvaluarPlan from './Components/Administracion/PlanDeAccion/EvaluarPlan';
import SegmentoPunto from './Components/ClientSide/SegmentoPunto/SegmentoPunto';
import AyudasDigitales from './Components/Administracion/AyudasDigitales/Ayudas';
import ReporteAudit from './Components/ClientSide/ReporteAuditoria/ReporteAudit';
import ListaPrecios from './Components/Administracion/ListaPrecios/ListaPrecios';
import ReportePuntos from './Components/ClientSide/ReportePorPuntos/ReportePuntos';
import CorreoContainer from './Components/Correos/CorreosContainer/CorreoContainer';
import ObjetoEstudio from './Components/Administracion/ObjetoEstudio/ObjetoEstudio';
import EstablecerPlan from './Components/Administracion/PlanDeAccion/EstablecerPlan';
import InicioContainer from './Components/MainFrame/InicioContainer/InicioContainer';
import MetodologiaView from './Components/Administracion/Metodologia/MetodologiaView';
import ControlContainer from './Components/Control/ControlContainer/ControlContainer';
import ReportContainer from './Components/ClientSide/ReportContainer/ReportContainer';
import EncuestaSurvey from './Components/Administracion/EncuestaSurvey/EncuestaSurvey';
import ReportePreguntas from './Components/ClientSide/ReportePreguntas/ReportePreguntas';
import ReporteSecciones from './Components/ClientSide/ReporteSecciones/ReporteSecciones';
import SegmentoPreguntas from './Components/ClientSide/SegmentoPregunta/SegmentoPregunta';
import ReporteAuditDet from './Components/ClientSide/ReporteAuditoriaDet/ReporteAuditDet';
import ReporteEncuestas from './Components/ClientSide/ReportePorEncuesta/ReporteEncuestas';
import Parametrizar from './Components/Administracion/ParametrizarColores/ParametrizarCol';
import ReportePuntosAcum from './Components/ClientSide/ReportePuntosAcum/ReportePuntosAcum';
import ObjetoEstudioView from './Components/Administracion/ObjetoEstudio/ObjetoEstudioView';
import ReporteEvaluacion from './Components/ClientSide/ReporteEvaluacion/ReporteEvaluacion';
import SegmentoPregunta from './Components/Administracion/SegmentoPregunta/SegmentoPregunta';
import ReporteSeccionesAcum from './Components/ClientSide/ReporteSeccionAcum/ReporteSeccionAcum';
import ReporteEncuestasAcum from './Components/ClientSide/ReporteEncuestaAcum/ReporteEncuestaAcum';
import ReporteSeccionesClima from './Components/ClientSide/ReporteSecciones/ReporteSeccionesClima';
import SegmentacionPuntos from './Components/Administracion/SegmentacionPuntos/SegmentacionPuntos';
import ReportePreguntasClima from './Components/ClientSide/ReportePreguntas/ReportePreguntasClima';
import ReportePreguntasAcum from './Components/ClientSide/ReportePreguntasAcum/ReportePreguntasAcum';
import ReportePreguntasAB from './Components/ClientSide/ReportePreguntasAbiertas/ReportePreguntasAbiertas';
import ReporteSeccionesClimaAcum from './Components/ClientSide/ReporteSeccionAcum/ReporteSeccionClimaAcum';
import ReportePreguntasClimaAcum from './Components/ClientSide/ReportePreguntasAcum/ReportePreguntasClimaAcum';
import ReporteFuturo from './Components/ClientSide/ReportedeFuturos/ReporteFuturo';
import EvaluationContainer from './Components/Active/EvaluationContainer/EvaluationContainer';
import Contactos from './Components/Correos/ListaContactos/Contactos';
import ReporteDesempeno from './Components/ClientSide/ReporteDesempeno/ReporteDesempeno';
import ReporteDesempenoNivel from './Components/ClientSide/ReporteDesempeno/ReporteDesempenoNivel';
import ReporteDesempenoRol from './Components/ClientSide/ReporteDesempeno/ReporteDesempenoRol';
import ReporteDesempenoParticipacion from './Components/ClientSide/ReporteDesempeno/ReporteDesempenoParticipacion';
import Bandeja from './Components/Correos/BandejaSalida/Bandeja';
import Statistics from './Components/Control/Statistics/Statistics';

import {ClientContextProvider} from './Context/ClientContext';
import { ParametroContextProvider } from './Context/ParametrosContext';
import {UserContextProvider} from './Context/UserContext'
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ReporteDesempenoGeneral from './Components/ClientSide/ReporteDesempeno/ReporteDesempenoGeneral';
import ForgotPassword from './Components/Control/ForgotPassword/ForgotPassword';
import GestionDiaria from './Components/ClientSide/GestionDiaria/GestionDiaria';
import GestionDetractor from './Components/ClientSide/GestionDetractor/GestionDetractor';
import TableroPrincipal from './Components/ClientSide/TableroPrincipal/TableroPrincipal';
import ReporteNPS from './Components/ClientSide/ReporteNPS/ReporteNPS';
import LinkedEncuesta from './Components/Active/LinkedEncuesta';
import ClientEncuesta from './Components/Active/ClientEncuesta';
import EStatistics from './Components/Control/EncuestadorStatistics/EStatistics';
import Registry from './Components/Control/Registry/Registry';

function App() {
  return (<>
  <UserContextProvider>
    <ClientContextProvider>
      <ParametroContextProvider>        
        <BrowserRouter>
        <Routes>
          <Route index element={<LoginScreen />} />
          <Route path="/" element={<LoginScreen />} />
          <Route path='/encuesta/:sp/:id' element={<ActiveEncuesta />} />
          <Route path='/encuesta/:sp/:id/:sec/:suc/:us' element={<LinkedEncuesta />} />
          <Route path='/encuesta/:sp/:id/:sec/:suc' element={<ClientEncuesta />} />
          <Route path='/forgot/:code' element = {<ForgotPassword />} />       
          <Route path="/app" element={<LandingPage />}>
            <Route path="inicio" element={ <InicioContainer />} />
            <Route path='administracion' element={ <AdminDashboard /> }>
                <Route path="nueva-encuesta" element={<EncuestaSurvey />} />
                <Route path='reporte-cliente' element={<ReportSheet />} />
                <Route path="periodos" element={<Periodos />}/>
                <Route path="encuestas" element={<Encuestas />}/>
                <Route path="secciones" element={<Secciones />}/>
                <Route path="segmento-pregunta" element={<SegmentoPregunta />}/>
                <Route path='lista-precio' element={<ListaPrecios />} />
                <Route path="preguntas" element={<Preguntas />}/>
                <Route path="videos" element={<Videos />}/>
                <Route path="objeto-estudio" element={<ObjetoEstudio />}/>
                <Route path="metodologia" element={<Metodologia />}/>
                <Route path="zonas" element={<Zonas />}/>
                <Route path="ciudades" element={<Ciudades />}/>
                <Route path="puntos" element={<Puntos />}/>
                <Route path="segmentacion-puntos" element={<SegmentacionPuntos />}/>
                <Route path="ayudas-digitales" element={<AyudasDigitales />}/>
                <Route path="plan-accion" element={<EstablecerPlan />}/>
                <Route path="parametrizar" element={<Parametrizar/>}/>
                <Route path="plan-accion/evaluacion" element={<EvaluarPlan/>}/>
            </Route>
            <Route path='reportes' element={<ReportContainer />}> 
              <Route path="gestion-diaria" element={<GestionDiaria />}/>
              <Route path="gestion-detractor" element={<GestionDetractor />}/>
              <Route path="tablero-principal" element={<TableroPrincipal />}/>
              <Route path="reporte-nps" element={<ReporteNPS />}/>
              <Route path="reporte-nps/:day/:denc/:dsuc/:dper" element={<ReporteNPS />}/>
              <Route path="plan-accion" element={<EstablecerPlan />}/>
              <Route path="plan-accion/evaluacion" element={<EvaluarPlan/>}/> 
              <Route path="objeto-estudio-view" element={<ObjetoEstudioView />}/>
              <Route path="metodologia-view" element={<MetodologiaView />}/>
              <Route path="listar-videos" element={<ListarVideos/>} />
              <Route path='reporte-secciones' element={<ReporteSecciones />} />
              <Route path='reporte-secciones-clima' element={<ReporteSeccionesClima />} />
              <Route path='reporte-desempeno' element={<ReporteDesempeno />} />
              <Route path='reporte-desempeno-general' element={<ReporteDesempenoGeneral />} />
              <Route path='reporte-desempeno-participacion' element={<ReporteDesempenoParticipacion />}/>
              <Route path='reporte-desempeno-nivel' element={<ReporteDesempenoNivel />} />
              <Route path='reporte-desempeno-Rol' element={<ReporteDesempenoRol />} />
              <Route path="reporte-encuestas" element={<ReporteEncuestas />} />
              <Route path="reporte-evaluacion" element={<ReporteEvaluacion />} />
              <Route path="reporte-puntos" element={<ReportePuntos />} />
              <Route path="reporte-futuros" element= {<ReporteFuturo />} />
              <Route path='reporte-preguntas' element={<ReportePreguntas />} />
              <Route path='reporte-preguntas-clima' element={<ReportePreguntasClima />} />
              <Route path="reporte-preguntas-abiertas" element={<ReportePreguntasAB />} />
              <Route path="reporte-preguntas-acum" element={<ReportePreguntasAcum />} />
              <Route path="reporte-preguntas-clima-acum" element={<ReportePreguntasClimaAcum />} />
              <Route path="reporte-puntos-acum" element={<ReportePuntosAcum />} />
              <Route path="reporte-encuesta-acum" element={<ReporteEncuestasAcum />} />
              <Route path="reporte-seccion-acum" element={<ReporteSeccionesAcum />} />
              <Route path="reporte-seccion-clima-acum" element={<ReporteSeccionesClimaAcum />} />
              <Route path="segmento-punto" element={<SegmentoPunto />} />
              <Route path="segmento-preguntas" element={<SegmentoPreguntas />} />
              <Route path="reporte-audit" element={<ReporteAudit />} />
              <Route path="reporte-audit-det" element={<ReporteAuditDet />} />
              <Route path="reporte-precios" element={<ReportShop />} />
            </Route>
            <Route path='user-control' element={<UserControl />} />
            <Route path='control' element={<ControlContainer />}>
              <Route path='statistics' element={<Statistics />} />
              <Route path='registry' element={<Registry />} />
              <Route path='estatistics' element={<EStatistics />} />
              <Route path='data-cruda' element={<DataCruda />} />              
              <Route path="objeto-estudio" element={<ObjetoEstudio />}/>
              <Route path="metodologia" element={<Metodologia />}/>
              <Route path="plan-accion/evaluacion" element={<EvaluarPlan/>}/>
              <Route path="reporte-plan" element={<ReportePA /> } />
            </Route>
            <Route path='correos' element={<CorreoContainer />}>
                <Route path='contactos' element={<Contactos />}/>
                <Route path='bandeja' element={<Bandeja />}/>
            </Route>
            <Route path='evaluacion' element={<EvaluationContainer />}>
            </Route>
          </Route>
          <Route path="*" element={null} />
        </Routes>
    </BrowserRouter>        
      </ParametroContextProvider>
    </ClientContextProvider>
  </UserContextProvider>
  </>);
}

export default App;
