import React, { useState, useEffect, useContext, useReducer } from "react";

const DataContext = React.createContext({});

export function DataContextProvider({ children }) {
  const [Data, setData] = useState({
      ciudades: [],
      periodos:[],
      encuestas:[],
      secciones:[],
      puntos:[],
      preguntas:[],
      reportes:[],
      joints:[],
  });


  useEffect(() => {},[])  
  
  return (
    <DataContext.Provider value={{ Data, setData }}>
      {children}
    </DataContext.Provider>
  );
}
export default DataContext;
