import { Row } from "react-bootstrap";
import { useEffect, useContext} from "react";
import UserContext from "../../../Context/UserContext";
import ClientContext from "../../../Context/ClientContext";
import Content from "../Content/Content";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./Landing.css";
import axios from "axios";

export default function LandingPage() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const newLocation = useNavigate();
    const {userData,setUser} = useContext(UserContext);
    const {setLog} = useContext(ClientContext);
    const activeSession = window.localStorage.getItem("sessionKey");

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
        }

    useEffect(()=>{
        if(userData.permiso !== 'admin' && userData.permiso.length > 0)
            { axios.get(API_URL + '/cliente', {params : {client_id : userData.cliente_id}}).then(
                function (response) {                                                        
                    setLog(response.data.find(p => p.id === userData.cliente_id))
                })
             }
        },[userData])

    useEffect(() => {
    if (activeSession) {
        let headers = setHeaders();
        axios.get(API_URL + "/me", { headers }).then(res => {
            let user = res.data
            user.user_id = user.id
            user.client_id = user.cliente_id            
            axios.post(API_URL+"/registry", user).then(ress => {
                console.log(ress.data)
            })
            setUser(res.data)
            newLocation("/app/inicio");
        })
    }
    else {
        newLocation("/");
    }
    }, [activeSession])
    
    return (<>
        <Row className="header">
            <Header />
        </Row>
        <Row className="content">
                <Content />
        </Row>
        <Row className="footer">
            <Footer />
        </Row>
    </>)
}