import { useState, useContext, useEffect } from "react";
import { Form, Button, Row, Col, Table} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiSearchAlt } from 'react-icons/bi'
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { BarLoader } from "react-spinners"
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import axios from "axios";
import { EChart } from '@kbox-labs/react-echarts'
import './Gestion.css'


export default function GestionDiaria() {
    const API_URL = 'https://api.medicionanalytic.com/api'        
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)    
    const navigate = useNavigate();

    const [DataNPS,setDNPS] = useState([])
    const [MensualNPS, setMNPS] = useState([])        
    const [reportes, setRep] = useState([])
    const [enc, setE] = useState()
    const [Reps, setReps] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    
    const [segmentos, setSegmentos] = useState([])
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])    
    const [puntos, setPuntos] = useState([])    
    
    const [preguntas, setPreg] = useState([])
    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])    
    const [PS, setPS] = useState([])        
    const [SD, setSD] = useState([])
    
    const [loader, setLoad] = useState(false)
    const Dates = Array(31).fill();
    const [minDate, setMinDate] = useState(0)
    const [maxDate, setMaxDate] = useState(30)

    //Graph config      
     const config = {
        rotate: 0,
        align: 'center',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: config.rotate,
            align: config.align,
            verticalAlign: config.verticalAlign,
            position: config.position,
            distance: config.distance
          };          
        }
      };
      const labelOption = {
        show: true,
        position: config.position,
        distance: config.distance,
        align: config.align,
        verticalAlign: config.verticalAlign,
        rotate: config.rotate,
        formatter: '{c}',
        fontSize: 16,        
      };
      const labelOption2 = {
        show: true,
        position: config.position,
        distance: config.distance,
        align: config.align,
        verticalAlign: config.verticalAlign,
        rotate: config.rotate,
        formatter: '{c}%',
        fontSize: 16,        
      };
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
      } = useForm({ mode: "onBlur" });      

    useEffect(()=>{                        
    axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"pregunta" } }).then((response) =>{
        let temp = response.data
        temp.forEach(el => {el.label = el.nombre_segmento; el.value = el.id;})        
        setSegmentos(temp.filter(t => { return t.aplica_calculo === 1}))
    })
        axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })                
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then((response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
                  res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
                  if(logData.id === 49) {setPuntos(res.data)}
                  else {setSuc(res.data)}                
              })
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then((response) => {
            let n;
            let temp;
            if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
            else { temp = response.data }
            if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
            else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
                setBaseEncuestas(n)
        })
        axios.get(API_URL + '/pregunta/all', {params: {client_id: logData.id}}).then((res)=>{setPreg(res.data)})

    },[])

    function getOccurrence(array, value) {return respuestas.filter((v) => (v.tipo_pregunta === value)).length;}    

    function Tabulada(){        
        let sum = []
        let res = respuestas        
        if(userData.permiso === "admin") {res = respuestas}
        else {
            res = respuestas.filter(el => {
                return respuestas.some(r=>{
                    return userData.puntos.split(",").indexOf(r.punto_id.toString()) > -1 || userData.secciones.split(",").indexOf(r.seccion_id.toString()) >-1 })
            })
        }        
        res.filter(r => r.encuesta_id === enc.id)
        .forEach((r)=>{                          
            if(r.tipo_pregunta === 'escala-tri' && r.respuesta !== "na") {
                if(r.respuesta === 'malo') { r.Value = 0; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r) }
                if(r.respuesta === 'regular') { r.Value = 50; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r) }
                if(r.respuesta === 'excelente') { r.Value =100; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r); }
              }                           
            if(r.tipo_pregunta === "escala" && r.respuesta !== "na") { r.Value =(r.respuesta*100)/r.valor_max; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r); }
              if(r.tipo_pregunta === "smiles") { r.Value =(r.respuesta*100)/5; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r); }
              if(r.tipo_pregunta === "thumbs") {
                if(r.respuesta === "si") { r.Value =100; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r); }
                else if(r.respuesta === "no") { r.Value = 0; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r) }
                else {r.Value ="na";r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento;r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r) }
              }
              if(r.tipo_pregunta === "stars"){ r.Value =(r.respuesta*100)/5; r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento; r.fecha = reportes.find(item => item.id === Number(r.reporte_id)).created_at; sum.push(r); }
              if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){                  
                    r.Value =(r.respuesta*100)/10;
                    r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento;
                    r.fecha = reportes.find(item => item.id === Number(r.reporte_id)) ? reportes.find(item => item.id === Number(r.reporte_id)).created_at : new Date()
                if(r.tipo_pregunta === "nps") {
                    r.texto = res.find(item => item.pregunta_id === Number(r.pregunta_id+1)) ? res.find(item => item.pregunta_id === Number(r.pregunta_id+1)).respuesta : "";
                    r.nivel = r.respuesta >= 8 ? "promotor" : r.respuesta <= 6 ? "detractor" : "neutro";
                }
                sum.push(r)                  
              }              
        })        
        return sum;
      }

      const getDateshort = (report) => {                
        const formattedDate = format(report, 'dd');            
        return formattedDate        
    }

    function showData(filtered) {            
        const values = getValues()            
        let DateData = []        
        let SegmentData = []
        let helper = []                           
        filtered.forEach(f => {if(f.fecha){f.day = getDateshort(f.fecha)}})        
        let x = filtered.filter(t => (t.Value >= 90 || t.Value <= 60) && t.tipo_pregunta === "nps")
        let y = filtered.filter(t => (t.Value < 90 && t.Value > 60 ) && t.tipo_pregunta === "nps")
        let totalength = x.length + y.length 
        //calculo de NPS total        
    let MNPS = []
    let DNPS = []    
      MNPS.push({
        y: (((x.filter(p => (p.periodo_id === Number(values.periodo_id) && p.Value >= 90)).length*100)/totalength) - ((x.filter(p => p.periodo_id === Number(values.periodo_id) && p.Value <= 60).length*100)/totalength)).toFixed(2),
        x: periodos.find(p => p.id === Number(values.periodo_id)).nombre_corto
      })
      DNPS.push({
        y:[ "0",
            (x.filter(p => (p.periodo_id === Number(values.periodo_id) && p.Value >= 90)).length*100)/totalength,
            (y.filter(p => p.periodo_id === Number(values.periodo_id)).length*100)/totalength,
            (x.filter(p => p.periodo_id === Number(values.periodo_id) && p.Value <= 60).length*100)/totalength,
          ],
        x: periodos.find(p => p.id === Number(values.periodo_id)).nombre_corto
      })            
    Dates.map((d,i) => {        
        let SegmentDays = []       
        let NPSdata = x.filter(f => Number(f.day) === Number(i+1))
        let num = filtered.filter(f => Number(f.day) === Number(i+1) && f.tipo_pregunta === "nps")        
        let data = filtered.filter(f => Number(f.day) === Number(i+1) && f.tipo_pregunta !== "texto")                        
        let NPS = NPSdata.filter(t => (t.Value >= 90))            
        let nps = NPSdata.filter(t => (t.Value <= 60))                    
        segmentos.filter(s => s.encuesta_id === enc.id).forEach(s => {
            let tot = data.filter(x => Number(x.Segmento) === s.id)
            let xS = tot.filter(t => (t.Value >= 90))            
            SegmentDays.push({
                segmento:s.nombre_segmento,
                segmento_id: s.id,
                val: num.length > 0 ? (xS.length*100/tot.length).toFixed(2) : "NaN",
                day: Number(i+1),    
            })
        })
        let Day = {            
            day: Number(i+1),
            nps:(NPS.length*100/num.length - nps.length*100/num.length).toFixed(2),
            enc: num.length,
            detrac:NPSdata.filter(p => p.Value <= 60).length,
        }
        DateData.push(Day);
        SegmentData.push(SegmentDays);
    })                   
                
    setSD(SegmentData)     
    setDF(DateData)
    setMNPS(MNPS)
    setDNPS(DNPS)    
    //setAtrr(AM)        
    setConsulta(true)
    setLoad(false)    
    }
     
    const SetFilters = (data,e) => {        
        const values = getValues()
        setLoad(true)
        e.preventDefault();
        let tab = Tabulada()        
        if(!enc) {setConsulta(false);return;}
        let temp = []                                        
        let init = new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/, ''))
        let end = new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, ''))   
        let reps = reportes.filter(r => {
            if ((new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() >= init.getTime()) 
                && (new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() <= end.getTime())) 
            {return r.periodo_id === Number(values.periodo_id) && r.encuesta_id === Number(enc.id) && r.sucursal === Number(values.punto_id)}        
          })             
        let repos = reportes.filter(v => v.periodo_id === Number(values.periodo_id) && v.encuesta_id === Number(enc.id) && v.sucursal === Number(values.punto_id))        
        if (values.periodo_id > 0 && values.punto_id > 0 && values.date_fin && values.date_init)
        {   setMinDate(new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/, '')).getDate()-1)
            setMaxDate(new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, '')).getDate()-1)
            temp = tab.filter(v => {return reps.some(rep => {
                return v.periodo_id === Number(values.periodo_id) && v.encuesta_id === Number(enc.id) && v.punto_id === Number(values.punto_id) && v.reporte_id === rep.id 
            }) })
        }
        else if (values.periodo_id > 0 && values.punto_id > 0) 
        { temp = tab.filter(v => v.periodo_id === Number(values.periodo_id) && v.encuesta_id === Number(enc.id) && v.punto_id === Number(values.punto_id)) }                      

        if (values.date_fin && values.date_init)
        { setReps(reps.filter(d => respuestas.find(r => r.reporte_id === d.id))) }
        else 
        { setReps(repos.filter(d => respuestas.find(r => r.reporte_id === d.id))) }
        setPS([periodos.find(p=> p.id === Number(values.periodo_id))])
        showData(temp)                        
    }    
    
    function getClass(val) {        
        let x = Number(val)
        if(isNaN(x)|| val === null){return "Fondo-Blank"}
        if(x <= 60 ) {return "Fondo-R"}
        if(x > 60 && x < 90) {return "Fondo-A"}
        if(x >= 90) {return "Fondo-V"}
    }    

    const handleEncuesta = (e) => {
        setLoad(true)    
        e.preventDefault()        
        let enc = encuestas.find(el => el.id === Number(e.target.value))        
        if(enc) {
            axios.get(API_URL+'/respuesta', {params: { encuesta_id:Number(e.target.value), periodo_id:getValues("periodo_id") }}).then(res=>{setR(res.data); setLoad(false)})
            axios.get(API_URL+'/reporte', {params:{encuesta:Number(e.target.value)}}).then(res =>{setRep(res.data)})
            setE(enc)            
        }
    }        
    const handleCity = (e) => {  
        e.preventDefault()               
        let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
        setSuc(sucursales)
    }
    const handlePerdiod = (e) => {    
        setValue("periodo_id", Number(e.target.value))
        setEncuestas([])    
        if(e && e.target.value !== null && e.target.value !== undefined) {        
        let temp = joints.filter(j => { return Number(j.periodo_id) === Number(e.target.value) })
        let temp2 = Basencuestas.filter(e => { return temp.some(j => {return Number(j.encuesta_id) === Number(e.id) }) })      
        setEncuestas(temp2)
        }
      }
return (<>    
    <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                        <Col>
                                <Form.Select onChange={handlePerdiod}>
                                    <option value={null}>Sin Periodo Seleccionado</option>
                                        {periodos.map(enc => {return <option value={enc.id}>{enc.nombre_periodo}</option>})}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select onChange={handleEncuesta} >
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>
                            {logData.id === 49 ? <Col>
                              <Form.Select onChange={handleCity} >
                                  <option value={null}>Sin Ciudad Seleccionada</option>
                                  {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                              </Form.Select> 
                            </Col>
                            : null}
                            <Col>
                                <Form.Select {...register("punto_id")}>
                                    <option value={null}>Sin Punto Seleccionado</option>
                                    { userData.permiso === "admin" ?
                                        Sucursales.map(enc => {return <option value={enc.id}>{enc.nombre_sucursal}</option>})
                                            :
                                        Sucursales.filter(el => { return  userData.puntos.split(",").indexOf(el.id.toString()) > -1 })
                                        .map(enc => {return <option value={enc.id}>{enc.nombre_sucursal}</option>}) 
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className='m-2 mt-1 mb-1'>Fecha inicio</Form.Label>
                                <Form.Control className='rmsc' type='date' {...register("date_init")} />
                            </Col>
                            <Col>
                                <Form.Label className='m-2 mt-1 mb-1'>Fecha fin</Form.Label>
                                <Form.Control className='rmsc' type='date' {...register("date_fin")} />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0" disabled = {loader}>
                                {loader ? <BarLoader className="m-2" color="#FFF" width={100} />:<>Aplicar filtros <BiSearchAlt/></> }
                            </Button>
                        </Row>
    </Form>
    {consulta ? 
    <div className="mb-4">
        <Row className="row-excel-button">
            <h3 className="p-2" style={{width:'50vw'}}>Gestion Diaria</h3>                        
        </Row>                
        <Row>
            <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">NPS</h4>                          
                <EChart className="Echart-smol" renderer={'SVG'} tooltip={{trigger:"item"}} legend={{bottom:'15%',left:'center'}}                                    
                    xAxis={{type: 'category',data: MensualNPS.map(m => {return m.x})}}
                    yAxis={{type: 'value'}}
                    series={[{data: MensualNPS.map(m => {return m.y}),type: 'bar',smooth: true, label: labelOption2,}]}/>
            </Col>   
            <Col className="collumn-alt bkg-white m-1">
                    <h4 className="m-2">Nro de encuestas</h4>                          
                    <EChart className="Echart-smol" renderer={'SVG'} tooltip={{trigger:"item"}}                            
                        xAxis= {{type: 'category',axisTick: { show: false },data: PS.map(p => p.nombre_corto),axisLabel: { interval: 0 }}}
                        yAxis={{type: 'value'}}
                        series= {[ { data: PS.map(p => {return Reps.filter(r => r.periodo_id === p.id).length}), type: 'bar', label: labelOption, } ]}
                    />
            </Col>                     
            <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">Detalle NPS</h4>                          
                <EChart className="Echart-smol" renderer={'SVG'} tooltip={{trigger:"item"}} legend={{bottom:'5%',left:'center'}}                                    
                            xAxis= {{type: 'category',data: DataNPS.map(d => {return d.x}), axisLabel: { interval: 0} }}
                            yAxis={{type: 'value'}}
                            series={[
                                { data: DataNPS.map(d => {return d.y[0]}),type: 'bar',stack: 'a',},
                                { data: DataNPS.map(d => {return (d.y[1]).toFixed(2)}),type: 'bar',stack: 'a',name: 'promotor',label: labelOption2,},
                                { data: DataNPS.map(d => {return (d.y[2]).toFixed(2)}),type: 'bar',stack: 'a',name: 'neutro',label: labelOption2,},
                                { data: DataNPS.map(d => {return (d.y[3]).toFixed(2)}),type: 'bar',stack: 'a',name: 'detractor',label: labelOption2,} ]}
                />                
            </Col>           
        </Row>        
        <Table className="App-table" responsive striped>
            <thead>
                <tr>
                    <th className="table-th sticky-col first-col">Dia</th>
                    {Dates.map((d,i) => {if(minDate <= i && maxDate >= i) {return <th className="table-th">{i+1}</th>}})}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="table-th sticky-col first-col">NPS</td>
                    {Dates.map((d,i) => {return dataFiltered.map(data => {
                        if(data.day === i+1 && minDate <= i && maxDate >= i) {
                            return <td className={getClass(data.nps)}>{data.nps+'%'}</td>
                        }
                    })})}
                </tr>
                <tr>
                    <td className="table-th sticky-col first-col">Enc Efectivas</td>
                    {Dates.map((d,i) => {return dataFiltered.map(data => {
                        if(data.day === i+1 && minDate <= i && maxDate >= i) {
                            return <td className="text-center clickable" onClick={() => navigate("../reporte-nps/"+data.day+'/'+enc.id+'/'+getValues("punto_id")+'/'+PS[0].id)} >{data.enc}</td>
                        }
                    })})}
                </tr>
                <tr>
                    <td className="table-th sticky-col first-col">Nro Detractores</td>
                    {Dates.map((d,i) => {return dataFiltered.map(data => {
                        if(data.day === i+1 && minDate <= i && maxDate >= i) {
                            return <td className="text-center">{data.detrac}</td>
                        }
                    })})}
                </tr>
                {segmentos.filter(s => s.encuesta_id === enc.id).map(s => {
                    return <tr>
                        <td className="table-th sticky-col first-col">{s.nombre_segmento}</td>
                        {SD.map((data,i) => {                                                                         
                            return data.map(res => {                    
                                if(Number(res.day) === i+1 && res.segmento_id === s.id && minDate <= Number(res.day)-1 && maxDate >= Number(res.day)-1)
                                { return <td className={getClass(res.val)}>{res.val+'%'}</td>}
                            })
                        })}
                    </tr>
                })}
            </tbody>
        </Table>
        </div>: null}
    </div>
    </>)

}