import { useEffect } from "react";
import "./Footer.css";
import { useColor } from "react-color-palette";


export default function Footer(bck) {
    const [primary_color, setPC] = useColor("#DAD8F6")
    useEffect(() => {            
        if(bck !== null || bck !== undefined) { setPC(bck.bck) }
    }, [bck])
    return(<>
    <div className="footer-container" style={{backgroundColor: primary_color ? primary_color.hex : "#DAD8F6"}}>
    <p><b>Medición Analytic Platform</b> by Pagovision Inc. 2024</p>
    </div>
    </>)
}