import {useState, useContext, useEffect, useReducer} from 'react'
import { Form, Button, Table, Row, Col, Modal, Toast, ToastContainer, InputGroup } from "react-bootstrap";
import axios from 'axios';
import UserContext from '../../../Context/UserContext';
import ClientContext from '../../../Context/ClientContext';
import {BiTrash, BiEditAlt, BiChevronDownSquare} from 'react-icons/bi';
import { read, utils } from 'xlsx';
import * as FileSaver from 'file-saver';
import { useForm } from "react-hook-form";
import './UserControl.css'

export default function UserControl() {
    const [SeccionList, setSL] = useState([])
    const [PointList, setPL] = useState([])
    const [EncuestaList, setEL] = useState([])
    const [SList, setSList] = useState([])
    const [ClientList, setClist] = useState([])
    const [SUS, setSUS] = useState([])
    const [SUP, setSUP] = useState([])
    const [SUE, setSUE] = useState([])
    const [CLI, setCLI] = useState([])
    const [SSUE, staticSUE] = useState([])
    const [SSUS, staticSUS] = useState([])
    const [SSUP, staticSUP] = useState([])
    const [SCLI, staticCLI] = useState([])
    const [selectedUser, setSU] = useState()
    const [encuestador,setEncuestador] = useState(false)

    const API_URL = 'https://api.medicionanalytic.com/api'
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/Files'
    const {userData, setUser} = useContext(UserContext)    
    const {logData} = useContext(ClientContext)
    const [userList, setList] = useState([])
    const [SNU, setShowNU] = useState(false)
    const [SCU, setShowCU] = useState(false)
    const [SUU, setShowUU] = useState(false)    
    const [DU, setDU] = useState(false)

    const [secciones, setSec] = useState([])
    const [puntos, setPunt] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [clientes, setClients] = useState([])
    const [MUU, setMUU] = useState([])

    const [loading, setLoad] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const { 
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isValid },
      } = useForm({ mode: "onBlur" });

    const showDU = (user) => {
        reset({id: user.id})
        setDU(true)
    }

    const hideDU = () => {
        setDU(false)
    }
    const showCU = () => {
        setShowCU(true)
    }
    const showNU = () => {
        reset({
            cliente_id: null,
            usuario: null,
            nombres:null,
            apellidos: null,
            cargo:null,
            email: null,
            password: null,
            gerencia: null,
            permiso: logData.servicio_desempeno === "activo" ? 'labor':'gerente',
            subscription : "trial",
        })
        setShowNU(true)
    }
    const hideNU = () => {
        setShowNU(false)
        setEncuestador(false)
    }
    const showUU = (user) => {
        reset({
            id: user.id,
            cliente_id: user.cliente_id,
            usuario: user.usuario,
            email: user.email,
            nombres:user.nombres,
            apellidos: user.apellidos,
            cargo: user.cargo,
            gerencia: user.gerencia,
            empresa: user.empresa,
            password: user.password,
            permiso: user.permiso,
            subscription : user.subscription,
        })
        setShowUU(true)
    }
    const hideUU = () => {
        setShowUU(false)
        setEncuestador(false)
    }
    const hideCU = () => {
        setShowCU(false)
        setEncuestador(false)
    }

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;}

    useEffect(() => {
        if(userData.subscription === "super") {
            axios.get(API_URL + '/cliente', {params : {admin_id : userData.id, subscription:userData.subscription}}).then(
                function (response) {                            
                    setClients(response.data)
                 })
        }
            axios.get(API_URL + '/usuario', {params: {cliente_id:logData.id}}).then(res => { setList(res.data) })
            axios.get(API_URL + '/sucursal',{ params:{cliente_id:logData.id}}).then((response) => {
                    setPunt(response.data)                    
            })
            axios.get(API_URL + '/seccion/report', { params: { client_id: logData.id } }).then((response) => {
                    setSec(response.data)                    
                })
            axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id} }).then(
                function(response) {
                    let n;
            let temp;
            if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
            else { temp = response.data }
            if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null);}
            else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null);}
                setEncuestas(n)
            })
    },[toast])

    const CreateUser = (data, e) => {
        e.preventDefault();
        let allpnts = puntos.map(p => {return p.id})
        let allsecs = secciones.map(s => {return s.id})
        let allencs = encuestas.map(e => {return e.id})
        data.cliente_id = logData.id
        if(data.permiso === "gerente") {                        
                data.puntos = allpnts.toString()
                data.secciones = allsecs.toString()
                data.encuestas = allencs.toString()        
        } else {data.puntos = ""; data.secciones = ""; data.encuestas = ""}                
        axios.post(API_URL + '/usuario', data).then((response) => {
            Toaster("success", response.data.message)
        })
        hideNU()
    }

    const MassUpload = (data,e) => {
        e.preventDefault()
        let headers = setHeaders()
        axios.post(API_URL + '/usuario/CU', MUU, {headers}).then((response) => {
            Toaster("success", response.data.message)            
            hideCU()
        })
    }

    const UpdateUser = (data, e) => {
        e.preventDefault();
        let headers = setHeaders()
        axios.post(API_URL + '/usuario/update', data, {headers}).then((response) => {
            Toaster("success", response.data.message)
            hideUU()
        })
    }

    const RemoveUser = (data, e) => {
        e.preventDefault();
        let headers = setHeaders();
        axios.delete(API_URL+`/usuario/${data.id}`, {headers}).then(res => {
            Toaster("success", "Usuario Eliminado")
            hideDU()
        })
        .catch(err => {Toaster("danger", "ocurrio un error")})
    }

    function HasSus(val, position) {
        let arr = []
        let value = val.toString()
        let temp = SeccionList

        if(SeccionList.indexOf(value) > -1)
        { 
            arr = SUS.map((item, index) => {return index === position ? !item: item;});
            temp.splice(SeccionList.indexOf(value), 1);
         }
         else {
             arr = SUS.map((item, index) => {return index === position ? !item: item;});
             temp.push(value)
        }
        setSL(temp)
        setSUS(arr)        
    }
    
    function HasSup(val, position) {

        let arr = []
        let value = val.toString()
        let temp = PointList                
        if(PointList.indexOf(value) > -1)
        { 
            temp.splice(PointList.indexOf(value), 1);        
            arr = SUP.map((item, index) => {return index === position ? !item: item;});            
        }
        else {
            temp.push(value)
            arr = SUP.map((item, index) => {return index === position ? !item: item;});            
        }                
        
        setPL(temp)
        setSUP(arr)
    }
    
    function HasCli(val, position) {
        let arr = []
        let value = val.toString()
        let temp = ClientList                
        if(ClientList.indexOf(value) > -1)
        { 
            temp.splice(ClientList.indexOf(value), 1);        
            arr = CLI.map((item, index) => {return index === position ? !item: item;});            
        }
        else {
            temp.push(value)
            arr = CLI.map((item, index) => {return index === position ? !item: item;});            
        }                        
        setPL(temp)
        setCLI(arr)
    }

    function HasSue(val, position) {        
        let arr = []
        let value = val.toString()
        let temp = EncuestaList
        if(EncuestaList.indexOf(value) > -1)
        { 
            temp.splice(EncuestaList.indexOf(value), 1);        
            arr = SUE.map((item, index) => {return index === position ? !item: item;});            
        }
        else {
            temp.push(value)
            arr = SUE.map((item, index) => {return index === position ? !item: item;});            
        }                
        
        setEL(temp)
        setSUE(arr)
    }

    function showSU(user) {
        if(selectedUser) {setSU()}
        setTimeout(() => {
            let sus = SSUS
            let sup = SSUP
            let sue = SSUE
            let scli = SCLI                    
            
            let Secciones = [];
            let Puntos = [];        
            let Encuestas = [];
            let Clientes = [];
            
            if(user.secciones){ Secciones = user.secciones.split(",") }
            if(user.puntos) { Puntos = user.puntos.split(",") }
            if(user.encuestas) { Encuestas = user.encuestas.split(",") }
            if(user.clients) {  Clientes = user.clients.split(",") }

            puntos.forEach((p, position) => {
                if(Puntos.indexOf(p.id.toString()) > -1) {sup[position] = true}
                else {sup[position] = false}
            })            
            secciones.forEach((p, position) => {
                if(Secciones.indexOf(p.id.toString()) > -1) {sus[position] = true }
                else {sus[position] = false}
            })
            encuestas.forEach((e,position) => {
                if(Encuestas.indexOf(e.id.toString()) > -1) {sue[position] = true}
                else {sue[position] = false}
            })            
            clientes.forEach((c,position) => {                
                if(Clientes.indexOf(c.id.toString()) > -1) {scli[position] = true}
                else {scli[position] = false}
            })            
            setSUS(sus)
            setSUP(sup)
            setSUE(sue)
            setCLI(scli)
            setSList(userList)
            setSU(user)
        }, 100)        
    }
    
    function sendUpdate() {
        let user = selectedUser
        user.secciones = SeccionList.toString()
        user.puntos = PointList.toString()
        user.encuestas = EncuestaList.toString()
        user.clients = ClientList.toString()
        //user.subordinados
        let headers = setHeaders()        
        axios.post(API_URL + '/usuario/update', user, {headers}).then((response) => {
            Toaster("success", response.data.message)
            setLoad(false)
            if(user.id === userData.id) {
                setUser(user)
            }
        })
        .catch(err => {
            Toaster("danger", "algo salio mal")
            setLoad(false)
        })
    }

    const handleImport = ($event) => {
        const files = $event.target.files;
        let row                
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    row = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    let users = []
                    let userList = [] 
                    row.forEach(r => {if(userList.findIndex(p => r.Identificación_Evaluador === p.Identificación_Evaluador) === -1){userList.push(r)}})                   
                    userList.forEach((user, index) => {
                        let curruser = 
                        {   usuario: user.Identificación_Evaluador,
                            jefes: "",
                            subordinados:"",
                            password: "P"+user.Identificación_Evaluador,
                            nombres: user.Evaluador,
                            gerencia: user.GRUPO_OCUPACIONAL,
                            cargo:user.cargo,
                            permiso: "labor",
                            cliente_id: logData.id,
                        }
                        row.filter(r => r.Identificación_Evaluador === user.Identificación_Evaluador)
                            .forEach(r => {
                                if(r.Relacion === "Jefe") {curruser.jefes = curruser.jefes.concat(r.Identificación_Evaluado,",");}
                                if(r.Relacion === "Colaborador") {curruser.subordinados = curruser.subordinados.concat(r.Identificación_Evaluado,",");}
                            })
                        users.push(curruser)
                    })                                          
                    setMUU(users)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    return <>
         <ToastContainer style={{ position: "fixed", top: "80vh", right: "0vw" }} className="p-3">
            <Toast
                bg={toast.variant}
                onClose={() => setToast({ show: false, variant: "", message: "" })}
                show={toast.show}
                delay={2000}
                autohide>
                <Toast.Header>
                    <strong className="me-auto">Medicion</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    <Modal show={SNU} onHide={hideNU}>
            <Modal.Header closeButton>
                <Modal.Title>Nuevo usuario</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(CreateUser)}>
                <Modal.Body>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>{logData.servicio_desempeno === "activo" ? "Numero de identificación": "Nombre del usuario"}</Form.Label>
                            <Form.Control size="sm" placeholder={logData.servicio_desempeno === "activo" ? "":"Usuario"} {...register("usuario")} />                    
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Direccion de correo</Form.Label>
                            <Form.Control size="sm" placeholder="correo" {...register("email")} />                    
                        </Form.Group>
                    </Row>
                    {logData.servicio_desempeno === "activo" ?<>                    
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control size="sm" placeholder="Nombres" {...register("nombres")} />                    
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Apellidos</Form.Label>
                            <Form.Control size="sm" placeholder="Apellidos" {...register("apellidos")} />                    
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Cargo</Form.Label>
                            <Form.Control size="sm" placeholder="Cargo" {...register("cargo")} />                    
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Gerencia</Form.Label>
                            <Form.Select {...register("gerencia")} >
                                {secciones.map(s => {return <option value={s.nombre_seccion}>{s.nombre_seccion}</option>})}
                            </Form.Select>                    
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label>Empresa</Form.Label>
                        <Form.Select {...register("empresa")}>
                            {puntos.map(s => {return <option value={s.nombre_sucursal}>{s.nombre_sucursal}</option>})}
                        </Form.Select>
                    </Form.Group>
                    </>: null}
                    <Form.Group className="mb-2">
                        <Form.Label>Contraseña</Form.Label>                        
                        <Form.Control size="sm" type="password" placeholder="Contraseña" {...register("password", {
                           onChange: (e) => {                            
                            if(!e.target.value.match(/[^a-zA-Z\d]/) || (!e.target.value.match(/[a-z]/) && !e.target.value.match(/[A-Z]/)) || !e.target.value.match(/\d/) || !e.target.value.length > 8) {}                                                        
                            else {}
                          },
                           onBlur: (e) => {
                            if(!e.target.value.match(/[a-z]/) && !e.target.value.match(/[A-Z]/)) { Toaster("danger", "Para una contraseña segura, incluye una combinación de letras mayusculas y minusculas.");return}
                            if(!e.target.value.match(/\d/)) { Toaster("danger", "Para una contraseña segura incluye por lo menos un numero.");return}
                            if(!e.target.value.length > 8) {Toaster("danger", "Las contraseñas seguras tienen un minimo de 8 caracteres.");return}                            
                           }
                          })} />                                            
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Permisos</Form.Label>
                        <Form.Select {...register("permiso")} onChange={(e) => setEncuestador(e.target.value)}>
                            <option value="gerente">gerente</option>
                            <option value="user">usuario</option>
                            <option value="encuestador">encuestador</option>
                            {logData.servicio_desempeno === "activo" ? <option value="labor">colaborador</option>: null}
                            {userData.permiso === 'admin' ? <option value="admin">Admin</option>: null}
                        </Form.Select>
                    </Form.Group>                    
                    {encuestador === "encuestador" ? null :
                    userData.subscription === "super" || userData.subscription === "client" ? <Form.Group className="mb-2">
                        <Form.Label className='mt-2'>tipo de subscripción</Form.Label>
                        <Form.Select {...register("subscription")}>                        
                            <option value="trial">prueba</option>
                            <option value="client">cliente</option>                            
                        </Form.Select>
                    </Form.Group>:
                    null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='App-button-alt' onClick={hideNU}>
                        Cerrar
                    </Button>
                    <Button type="submit" className='App-button' disabled = {!loading && isValid ? false:true}>
                        Crear
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    <Modal show={SUU} onHide={hideUU}>
            <Modal.Header closeButton>
                <Modal.Title>Modificar usuario</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(UpdateUser)}>
                <Modal.Body>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                        <Form.Label>{logData.servicio_desempeno === "activo" ? "Numero de identificación": "Nombre del usuario"}</Form.Label>
                        <Form.Control size="sm" placeholder={logData.servicio_desempeno === "activo" ? "":"Usuario"} {...register("usuario")} />                    
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Direccion de correo</Form.Label>
                            <Form.Control size="sm" placeholder="correo" {...register("email")} />                    
                        </Form.Group>
                    </Row>
                    {logData.servicio_desempeno === "activo" ?<>                    
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control size="sm" placeholder="Nombres" {...register("nombres")} />                    
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Apellidos</Form.Label>
                            <Form.Control size="sm" placeholder="Apellidos" {...register("apellidos")} />                    
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Cargo</Form.Label>
                            <Form.Control size="sm" placeholder="Cargo" {...register("cargo")} />                    
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Gerencia</Form.Label>
                            <Form.Select {...register("gerencia")} >
                                {secciones.map(s => {return <option value={s.nombre_seccion}>{s.nombre_seccion}</option>})}
                            </Form.Select>                    
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label>Empresa</Form.Label>
                        <Form.Select {...register("empresa")}>
                            {puntos.map(s => {return <option value={s.id}>{s.nombre_sucursal}</option>})}
                        </Form.Select>
                    </Form.Group>
                    </>: null}
                    <Form.Group className="mb-2">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control size="sm" className='mb-1' type="password" placeholder="Contraseña" {...register("password", {
                           onChange: (e) => {                            
                            if(!e.target.value.match(/[^a-zA-Z\d]/) || (!e.target.value.match(/[a-z]/) && !e.target.value.match(/[A-Z]/)) || !e.target.value.match(/\d/) || !e.target.value.length > 8) {}                                                        
                            else {}
                          },
                           onBlur: (e) => {
                            if(!e.target.value.match(/[a-z]/) && !e.target.value.match(/[A-Z]/)) { Toaster("danger", "Para una contraseña segura, incluye una combinación de letras mayusculas y minusculas.");return}
                            if(!e.target.value.match(/\d/)) { Toaster("danger", "Para una contraseña segura incluye por lo menos un numero.");return}
                            if(!e.target.value.length > 8) {Toaster("danger", "Las contraseñas seguras tienen un minimo de 8 caracteres.");return}                            
                           }
                          })} />                                            
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Permisos</Form.Label>
                        <Form.Select {...register("permiso")} onChange={(e) => setEncuestador(e.target.value)}> 
                            {logData.servicio_desempeno === "activo" ? <option value="labor">colaborador</option>: null}
                            <option value="gerente">gerente</option>
                            <option value="user">usuario</option>
                            <option value="encuestador">encuestador</option>                            
                            {userData.permiso === 'admin' ? <option value="admin">Admin</option>: null}
                        </Form.Select>
                    </Form.Group>                                        
                    {encuestador === "encuestador" ? null :
                    userData.subscription === "super" || userData.subscription === "client" ? <Form.Group className="mb-2">
                        <Form.Label className='mt-2'>tipo de subscripción</Form.Label>
                        <Form.Select {...register("subscription")}>                        
                            <option value="trial">prueba</option>
                            <option value="client">cliente</option>                            
                        </Form.Select>
                    </Form.Group>:
                    null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='App-button-alt' onClick={hideUU}>
                        Cerrar
                    </Button>
                    <Button type="submit" className='App-button'>
                        Actualizar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    <Modal show={DU} onHide={hideDU}>
    <Modal.Header closeButton>
        <Modal.Title>Eliminar usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Está seguro? esta acción no puede deshacerse.</p>
        </Modal.Body>
        <Form onSubmit={handleSubmit(RemoveUser)}>
            <Modal.Footer>
                <Button className='App-button-alt' onClick={hideDU}>Cerrar</Button>
                <Button className='App-button' type='submit'>Eliminar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={SCU} onHide={hideCU}>
    <Modal.Header closeButton>
        <Modal.Title>Cargar usuarios</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(MassUpload)}>
            <Modal.Body>
                <Form.Label>Cargue el archivo excel con la data pertinente. </Form.Label>
                <Form.Control type="file" required onChange={handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            <Row>
                <Button className='App-button m-3' onClick={() => FileSaver.saveAs(Image_URL+"/PlantillaUsuarios.xlsx")}>Descargue el formato aqui</Button>
            </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className='App-button-alt' onClick={hideCU}>Cerrar</Button>
                <Button className='App-button' type='submit'>Subir</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <div className="content-container p-4">
        <div className="content-body-alt mb-4">
            <h5>Control de usuarios</h5>
            <div className="inner-list">
                <Table hover className='App-Table' striped>
                    <thead>
                        <th className='table-th'>Usuario</th>
                        <th className='table-th'>Cargo</th>
                        <th className='table-th'>Correo</th>
                        <th className='table-th'>Acciones</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='table-td'>{userData.usuario}</td>
                            <td className='table-td'>{userData.permiso}</td>
                            <td className='table-td'>{userData.email}</td>
                            <td className="clickable-end">
                            <td className='clickable' onClick={()=>{showUU(userData)}}><BiEditAlt /></td>
                            </td>
                        </tr>
                        {userList.filter(u => u.id !== userData.id)
                        .map(list => {
                            return <tr>
                                <td className='table-td'>{list.usuario}</td>
                                <td className='table-td'>{list.permiso}</td>
                                <td className='table-td'>{list.email}</td>
                                <td className="clickable-end">
                                   <tr>
                                   {list.permiso === 'admin' && list.id === 1 ? null : <>
                                    <td className='clickable' onClick={()=>{showDU(list)}}><BiTrash /></td>
                                    <td className='clickable' onClick={()=>{showUU(list)}}><BiEditAlt /></td>
                                    <td className='clickable' onClick={()=>{                 
                                        staticSUE(new Array(encuestas.length).fill(false))                                  
                                        staticSUS(new Array(secciones.length).fill(false))
                                        staticSUP(new Array(puntos.length).fill(false))
                                        staticCLI(new Array(clientes.length).fill(false))
                                        showSU(list)}}><BiChevronDownSquare /></td>
                                    </>
                                    }
                                   </tr>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        <Button className='App-button m-3' onClick={showNU}>nuevo usuario</Button>        
        <Button className='App-button m-3' onClick={showCU}> Importar usuarios</Button>
        </div>
        {selectedUser ? <div className='content-body'>
            <div className="inner-list">
                {/*populate list with user's data.*/}
                 <Row>
                    <h5>Permisos para el usuario {selectedUser.usuario}</h5>
                    {(logData.servicio_encuesta === "activo" || logData.servicio_mystery === "activo") ? <>
                        <Col>
                            <div className='top-box m-2 mb-0'>
                                <InputGroup className="m-2 mb-0 pt-2">                                            
                                    <Form.Label><h6>Encuestas</h6></Form.Label>                                
                                </InputGroup>
                            </div>
                            <div className="boxed m-2 mt-0">
                                {encuestas.map((s, index) => {
                                    return  <InputGroup className="m-2">                                    
                                                <Form.Check defaultChecked={SUE.length > 0 ? SUE[index]: false} onClick={() => { HasSue(s.id, index) }} label={s.nombre_encuesta} />                                
                                            </InputGroup>
                                })}
                            </div>
                        </Col>
                        <Col>
                            <div className='top-box m-2 mb-0'>
                                <InputGroup className="m-2 mb-0 pt-2">                                            
                                    <Form.Label><h6>Secciones</h6></Form.Label>                                
                                </InputGroup>
                            </div>
                            <div className="boxed m-2 mt-0">
                                {secciones.map((s, index) => {
                                    return  <InputGroup className="m-2">                                    
                                                <Form.Check defaultChecked={SUS.length > 0 ? SUS[index]: false} onClick={() => { HasSus(s.id, index) }} label={s.nombre_seccion} />                                
                                            </InputGroup>
                                })}
                            </div>
                        </Col>
                        <Col>
                            <div className='top-box m-2 mb-0'>
                                <InputGroup className="m-2 mb-0 pt-2">                                            
                                    <Form.Label><h6>Sucursales</h6></Form.Label>                                
                                </InputGroup>
                            </div>
                            <div className="boxed m-2 mt-0">
                                {puntos.map((p, index) => {
                                    return  <InputGroup className="m-2">                                            
                                                <Form.Check defaultChecked={SUP.length > 0 ? SUP[index]: false} onClick={() => { HasSup(p.id, index) }} label={p.nombre_sucursal} />                                
                                            </InputGroup>
                                })}
                            </div>
                        </Col>                        
                    </> : null}
                    {logData.servicio_desempeno === "activo" ? <>
                        <Col>
                            <div className='top-box m-2 mb-0'>
                                <InputGroup className="m-2 mb-0 pt-2">                                            
                                    <Form.Label><h6>Colaboradores</h6></Form.Label>                                
                                 </InputGroup>
                            </div>
                            <div className="boxed m-2 mt-0">
                                {SList.map((p, index) => {
                                    return  <InputGroup className="m-2">                                            
                                                <Form.Check label={p.nombres} />                                
                                        </InputGroup>
                                })}
                            </div>
                        </Col>  
                    </>:null}
                    {userData.subscription === "super" ? 
                    <Col>
                        <div className='top-box mb-0'>
                                <InputGroup className="m-2 mb-0 pt-2">                                            
                                    <Form.Label><h6>Clientes</h6></Form.Label>                                
                                </InputGroup>
                            </div>
                        <div className="boxed">
                        {clientes.map((c, index) => {
                            return <InputGroup className='m-2'>
                                    <Form.Check defaultChecked={CLI.length > 0 ? CLI[index]: false} onClick={() => { HasCli(c.id, index) }} label={c.nombre_cliente} />                                
                            </InputGroup>
                        })}
                        </div>
                    </Col>
                    :null}
                </Row>                               
                <Row className='m-2'>
                    <Button onClick={() => {sendUpdate();setLoad(true)}} disabled={loading} className='App-button'>Aplicar</Button>
                </Row>
            </div>
        </div> : null}
    </div></>
}