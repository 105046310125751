import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import { BarLoader } from "react-spinners";
import ParametrosContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";
import UserContext from "../../../Context/UserContext";

export default function SegmentoPreguntas() {
    const API_URL = 'https://api.medicionanalytic.com/api'    
    const {paramData} = useContext(ParametrosContext);
    const {logData} = useContext(ClientContext);
    const {userData} = useContext(UserContext);

    const [consulta, setConsulta] = useState(false)

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()
    
    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])
    const [sSegmentos, setSSeg] = useState([])

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [preguntas, setPreguntas] = useState([])
    const [preguntasBase, setPreguntasBase] = useState([])

    const [respuestas, setR] = useState([])
    const [segmentos, setSegmentos] = useState([])
    const [dataFiltered, setDF] = useState([])
    const [FS, setFS] = useState([])
    const [AR, setAR] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])
    const [loader, setLoad] = useState(false)

    const {
        handleSubmit,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = dataFiltered
        Document.forEach((d) => {delete d.tipo_pregunta;
            delete d.punto_id;
            delete d.periodo_id;
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    useEffect(() => {
        axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })                
        axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"pregunta" } }).then((response) =>{
            let temp = response.data
            temp.forEach(el => {el.label = el.nombre_segmento; el.value = el.id;})
            setSegmentos(temp)
        })        
        axios.get(API_URL + '/pregunta/all', {params: {client_id: logData.id}}).then((res)=>{
            setPreguntasBase(res.data)
        })
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            (response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
                res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
                if(logData.id === 49) {setPuntos(res.data)}
                else {setSuc(res.data)}                
                })
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then(
            (response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then((response) => {
            let n;
            let temp;
            if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
            else { temp = response.data }
            if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null);}
            else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null);}
                setBaseEncuestas(n)
        })
        
    },[])

    function getClass(val) {
        let x = Number(val)
        if(x < paramData.values.amarillo) {return "Fondo-R"}
        if(x < paramData.values.verde) {return "Fondo-A"}
        if(x >= paramData.values.verde) {return "Fondo-V"}
    }

function Tabulada(){    
    let data; 
    let sum = []                        
    respuestas.filter(r => {return r.encuesta_id === enc.id})
    .forEach((r)=>{
        if(preguntas.filter(p => {return Secciones.some(s => {return p.seccion_id === s.id})})
            .find(p => p.id === r.pregunta_id)) {
            if(r.tipo_pregunta === 'escala-tri') {
                if(r.respuesta === 'malo') {
                    data = {
                        Respuesta_id: r.id,
                    Value :0,
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                    Pregunta: r.pregunta,
                    Pregunta_id: r.pregunta_id,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
                    }
                    sum.push(data)
                }
                if(r.respuesta === 'regular') {
                    data = {
                        Respuesta_id: r.id,
                        Value :50,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                        Pregunta: r.pregunta,
                        Pregunta_id: r.pregunta_id,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
                if(r.respuesta === 'excelente') {
                    data = {
                        Respuesta_id: r.id,
                        Value :100,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                        Pregunta: r.pregunta,
                        Pregunta_id: r.pregunta_id,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
            }
            if(r.tipo_pregunta === "escala") {
            data = {            
                    Respuesta_id: r.id,
                    Value : (r.respuesta*100)/r.valor_max, 
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                    Pregunta: r.pregunta,
                    Pregunta_id: r.pregunta_id,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
              }
                sum.push(data)
            }
            if(r.tipo_pregunta === "thumbs") {
                if(r.respuesta === "si") {
                    data = {
                        Respuesta_id: r.id,
                        Value :100,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                        Pregunta: r.pregunta,
                        Pregunta_id: r.pregunta_id,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
                else if(r.respuesta === "no"){
                    data = {
                        Respuesta_id: r.id,
                        Value :0,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                        Pregunta: r.pregunta,
                        Pregunta_id: r.pregunta_id,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                  }
                    sum.push(data)
                }
            }
            if(r.tipo_pregunta === "stars"){
                  data = {            
                      Respuesta_id: r.id,
                      Value : r.respuesta >= 4 ? (r.respuesta*100)/5 : 0, 
                      Reporte_id: r.reporte_id, 
                      Punto_id: r.punto_id,
                      Periodo_id: r.periodo_id,
                      Encuesta_id: r.encuesta_id, 
                      Seccion_id: r.seccion_id,
                      Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                      Pregunta: r.pregunta,
                      Pregunta_id: r.pregunta_id,
                      Tipo_pregunta: r.tipo_pregunta,
                      Video_id : r.video_id
                }
                  sum.push(data)
            }
            if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){
                  data = {            
                      Respuesta_id: r.id,
                      Value : r.respuesta >= 9 ? (r.respuesta*100)/10 : 0, 
                      Reporte_id: r.reporte_id, 
                      Punto_id: r.punto_id,
                      Periodo_id: r.periodo_id,
                      Encuesta_id: r.encuesta_id, 
                      Seccion_id: r.seccion_id,
                      Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                      Pregunta: r.pregunta,
                      Pregunta_id: r.pregunta_id,
                      Tipo_pregunta: r.tipo_pregunta,
                      Video_id : r.video_id
                }
                  sum.push(data)
            }
            if(r.tipo_pregunta === "smiles"){
                  data = {            
                      Respuesta_id: r.id,
                      Value : r.respuesta >= 4 ? (r.respuesta*100)/5 : 0, 
                      Reporte_id: r.reporte_id, 
                      Punto_id: r.punto_id,
                      Periodo_id: r.periodo_id,
                      Encuesta_id: r.encuesta_id, 
                      Seccion_id: r.seccion_id,
                      Segmento: Number(preguntas.find(p => p.id === r.pregunta_id).segmento),
                      Pregunta: r.pregunta,
                      Pregunta_id: r.pregunta_id,
                      Tipo_pregunta: r.tipo_pregunta,
                      Video_id : r.video_id
                }
                  sum.push(data)
            }
            if(r.tipo_pregunta === "audit"){
                if(r.respuesta === "si") {
                    data = {
                        Respuesta_id: r.id,
                        Value :100,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
                else if(r.respuesta === "no"){
                    data = {
                        Respuesta_id: r.id,
                        Value :0,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                  }
                    sum.push(data)
                }
            }
        }
    })
    segmentos.forEach(s => {
        preguntas.forEach(p => {
            if(p.segmento === s.id && p.tipo_pregunta !== "pregunta")
            {s.seccion_id = p.seccion_id}
        })
    })//añadir seccion a segmentos para poder filtrar        
    return sum;
  }

const SetFilters = () => {    
    let tab = Tabulada()        
    if(!enc) {setConsulta(false);return;}        
    setPreguntas(preguntasBase.filter(preg => { return sSecciones.some(s =>  {return Number(preg.seccion_id) === Number(s.id)})}));
    let temp = []
    temp =  tab.filter(el => {
        return sPuntos.some(p => {
            return sSecciones.some(s => {
               return sPeriods.some(f => {
                return p.id === el.Punto_id && 
                s.id === el.Seccion_id && 
                f.id === el.Periodo_id && 
                el.Encuesta_id === enc.id
               })
            })
        })
    })            
    if(sSegmentos.length > 0 ) {temp = temp.filter(h => { return sSegmentos.some(s => {return h.Segmento !== null && h.Segmento === s.value;}) }); setFS(sSegmentos) }
    else { temp = temp.filter(h => h.Segmento !== null); setFS(segmentos) }    
    showData(temp)    
}

function getOccurrence(array, value) {return array.filter((v) => (v.Pregunta_id === value)).length;}

function getSegment(array, value) {return array.filter((v) => (v.Segmento === value)).length;}

function showData(res) {    
    let helper = []
    let maxHelper = []            
    res.forEach(v => {
        const bat = getOccurrence(res, v.Pregunta_id)
        if (bat > 0) {
        const indet = helper.findIndex(o => o.Pregunta_id === v.Pregunta_id)
        if(indet === -1) {
            let temp = res.filter((q) => (q.Pregunta_id === v.Pregunta_id))            
            switch(v.Tipo_pregunta) {                
                default : var result = {
                    Reporte_id: v.Reporte_id,
                    Periodo_id: v.Periodo_id,
                    Encuesta_id: v.Encuesta_id,            
                    Punto_id : v.Punto_id,
                    Nombre_sucursal: v.Nombre_sucursal,
                    Seccion_id : v.Seccion_id,
                    Pregunta : v.Pregunta,
                    Pregunta_id :v.Pregunta_id,
                    Tipo_pregunta : v.Tipo_pregunta,
                    Segmento : v.Segmento,
                    Value : (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/temp.length).toFixed(2) }
                helper.push(result)
            }            
         }
       }
       else {
        var result = {
            Reporte_id: v.Reporte_id,
            Periodo_id: v.Periodo_id,
            Encuesta_id: v.Encuesta_id,            
            Punto_id : v.Punto_id,
            Nombre_sucursal: v.Nombre_sucursal,
            Seccion_id : v.Seccion_id,
            Pregunta : v.Pregunta,
            Pregunta_id :v.Pregunta_id,
            Tipo_pregunta : v.Tipo_pregunta,
            Segmento : v.Segmento,
            Value : (v.Value).toFixed(2),
        }
            helper.push(result)
       }
    })
    helper.forEach(v => {
        const bar = getSegment(helper, v.Segmento)        
        if (bar > 1) {
        const indet = maxHelper.findIndex(o => o.Segmento === v.Segmento)
        if(indet === -1) {
            let temp = helper.filter((q) => (q.Segmento === v.Segmento))            
            var result = {
                Reporte_id: v.Reporte_id,
                Periodo_id: v.Periodo_id,
                Encuesta_id: v.Encuesta_id,            
                Punto_id : v.Punto_id,
                Nombre_sucursal: Sucursales.find(p => p.id === v.Punto_id).nombre_sucursal,
                Seccion_id : v.Seccion_id,
                Pregunta : v.Pregunta,
                Pregunta_id :v.Pregunta_id,
                Tipo_pregunta : v.Tipo_pregunta,
                Segmento : v.Segmento,
                Value : (temp.reduce(function (acc, obj) { return acc + Number(obj.Value) }, 0)/temp.length).toFixed(2) }
            maxHelper.push(result)
         }
       }
       else {
        var result = {
            Reporte_id: v.Reporte_id,
            Periodo_id: v.Periodo_id,
            Encuesta_id: v.Encuesta_id,            
            Punto_id : v.Punto_id,
            Nombre_sucursal: v.Nombre_sucursal,
            Seccion_id : v.Seccion_id,
            Pregunta : v.Pregunta,
            Tipo_pregunta : v.Tipo_pregunta,
            Pregunta_id :v.Pregunta_id,
            Segmento : v.Segmento,
            Value : Number(v.Value).toFixed(2),}
            maxHelper.push(result)
       }
    })    

    if(helper.length <= 0) {return setFS([])}        
    setDF(helper)    
    setAR(maxHelper)    
    setConsulta(true)
}

const handleEncuesta = (e) => {
    setLoad(true)
    e.preventDefault()
    setSS([])
    let sec = []
    if(userData.permiso === "admin")   
    { sec = secciones}
    else {
      sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
    }
    let enc = encuestas.find(el => el.id === Number(e.target.value))
    let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
    if(enc) {
        axios.get(API_URL+'/respuesta', {params: { encuesta_id: Number(e.target.value), periodo_id: sPeriods}}).then(res=>{
            let temp = res.data             
            let temp2 = [] 
            axios.get(API_URL + '/video', { params: { client_id: logData.id} }).then((response) => {                 
                let videos = response.data.filter(v => {return v.estado !== "inactivo"})                                
                if(userData.permiso === "admin" || userData.permiso === "gerente") {temp2 = temp; }
                else { let tetemp = temp.filter(el=> { return userData.encuestas.split(",").some(s => {return Number(el.encuesta_id) === Number(s) }) })                   
                temp2 = tetemp.filter(p => { return videos.some(v => {return (p.video_id === v.id || p.video_id === null)})}); }
                setR(temp2);setLoad(false)
            })
        })   
        setE(enc)
        setS(tem)
    } 
} 
const handleCity = (e) => {  
    e.preventDefault()       
    setSPtos([])        
    let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
    setSuc(sucursales)
}
const handlePerdiod = (e) => {    
    setEncuestas([])    
    if(sPeriods.length > 0) {        
    let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) })})
    let temp2 = Basencuestas.filter(e => { return temp.some(j => {return Number(j.encuesta_id) === Number(e.id) }) })      
    setEncuestas(temp2)
    }
  }  
    return (<>
    <div className="content-container">
    <Form>
        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 onMenuToggle={handlePerdiod}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onClick={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ?encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>
        </Row>
        <Row className="mb-1">
                            <Col>
                            {logData.id === 49 ? 
                                <Form.Select onChange={handleCity} >
                                    <option value={null}>Sin Ciudad Seleccionada</option>
                                    {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                                </Form.Select>
                                : <MultiSelect
                                overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Secciones",
                                "create": "Create",}
                                }
                                options={Secciones}
                                value={sSecciones}
                                onChange={setSS}
                                labelledBy="Select"
                            />}
                            </Col>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                  options={userData.permiso === "admin" ?
                                  Sucursales   :
                                  Sucursales.filter(el => {                                        
                                             return  userData.puntos.split(",").indexOf(el.id.toString()) > -1                                    
                                     })}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
        </Row>
        <Row className="mb-1">
            <Col>
                  <MultiSelect
                   overrideStrings={{
                     "allItemsAreSelected": "Todos los Segmentos Seleccionados.",
                     "clearSearch": "Limpiar busqueda",
                     "clearSelected": "Limpiar Seleccion",
                     "noOptions": "Sin opciones",
                     "search": "Buscar",
                     "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                    "selectSomeItems": "Seleccionar Segmentos(opcional)",
                     "create": "Create",}
                   }
                 options={enc ? segmentos.filter(s => {return s.encuesta_id === enc.id}) : []}
                 value={sSegmentos}
                 onChange={setSSeg}
                 labelledBy="Select"
                />
            </Col>
            <Col>
            {logData.id === 49 ? <MultiSelect overrideStrings={{ "allItemsAreSelected": "Todo Seleccionado.","clearSearch": "Limpiar busqueda","clearSelected": "Limpiar Seleccion","noOptions": "Sin opciones", "search": "Buscar","selectAll": "Todos","selectAllFiltered": "Todos (Filtrado)", "selectSomeItems": "Seleccionar Secciones", "create": "Create",}} options={Secciones} value={sSecciones} onChange={setSS} labelledBy="Select" /> : null}
            </Col>
        </Row>
        <Row className="m-1 mt-3">            
            <Button onClick={SetFilters} className="App-button m-2 mt-0" disabled = {loader}>
                {loader ? <BarLoader className="m-2" color="#FFF" width={100} />:<>Aplicar filtros <BiSearchAlt/></> }
            </Button>                
        </Row>
    </Form>
    {consulta ? 
    <div className="content-body-alt mb-4">
        <Row className="row-excel-button">
            <h3 className="p-2 " style={{width:'50vw'}}>Segmentacion de preguntas</h3>
            <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>    
        </Row>
        <Table className="App-table" responsive striped hover>
                    <thead>
                        <tr>
                            <th className="table-th">Segmento</th>
                            <th className="table-th extended">Pregunta</th>
                            <th className="table-th">Total detallado</th>
                            <th className="table-th">Total</th>
                        </tr>
                    </thead>
                    <tbody>                        
                    {FS.filter(f => f.encuesta_id === enc.id)                    
                    .map(seg => {
                        let shownSegment = []                            
                            return preguntas
                            .filter(p => Number(p.segmento) === seg.id 
                            && p.tipo_pregunta !== "texto" && p.tipo_pregunta !== "comentario" && p.tipo_pregunta !== "info" && p.tipo_pregunta !== "select" && p.tipo_pregunta !== "selectm" && p.tipo_pregunta !== "load")
                            .map(p => { 
                                let segmentindex = shownSegment.findIndex(obj => obj.id === seg.id)
                                if(segmentindex === -1) {shownSegment.push(seg)}
                                return <tr>
                                    {segmentindex === -1 ? <td className="table-td">{seg.nombre_segmento}</td> : <td></td>}
                                    <td className="Default-Fondo expanded">{p.titulo}</td>
                                    <td className="table-td">
                                    {dataFiltered.filter(d => d.Pregunta_id === p.id)
                                            .map( d => {                                            
                                            return <tr className="result-cell centered-td">
                                                <td className={d.Tipo_pregunta === "nps" ? "" :getClass(d.Value)}>{d.Tipo_pregunta === "nps" ? "" : d.Value +''}</td>
                                                </tr>})  
                                        }</td>            
                                    {segmentindex === -1 ? <td className="result-cell centered-td">{AR.map(ar =>{ return ar.Segmento === seg.id ? <td className={ar.Tipo_pregunta === "nps" ? "": getClass(ar.Value)}>{ar.Tipo_pregunta === "nps" ? "Visualizar en reporte NPS" : ar.Value+'%'}</td> :null })}</td>
                                    : <td></td>}
                                </tr>                        
                            }) 
                        })}
                    </tbody>
                </Table>
        </div>: null}
    </div>
    </>)
}