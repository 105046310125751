import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col, Modal, Toast, ToastContainer} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";

export default function ReporteAuditDet() {
    
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const API_URL = 'https://api.medicionanalytic.com/api'    
    const {logData} = useContext(ClientContext);
    const {userData} = useContext(UserContext);
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])

    const [dataDetalle, setDetalle] = useState([])
    const [consulta, setConsulta] = useState(false)
    const [imgModal, setIM] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
      } = useForm({ mode: "onBlur" });
    
    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    const hideImg = () => {setIM(false)}
    const showImg = (res) => {
        reset({
            image: Image_URL + res.imagen
        })
        setIM(true)
    }

         //format for excel Download
         const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
         const fileExtension = '.xlsx';
     
         const exportToExcel = async () => {
            //añadir campos extra.
             let Document = dataDetalle
             Document.forEach((d) => {
                delete d.updated_at;
                delete d.created_at;
                delete d.video_id;
                delete d.segmento;
                d.periodo = periodos.find(p => p.id === d.periodo_id).nombre_periodo
                d.sucursal = puntos.find(pt => pt.id === d.punto_id).label
                d.encuesta = encuestas.find(e=>e.id === d.encuesta_id).nombre_encuesta
                d.seccion = secciones.find(s=>s.id === d.seccion_id).nombre_seccion
                if(d.tipo_pregunta !== 'escala')
                {d.valor_max = null;}
                if(d.aplica == 1) { d.aplica = "si" }
                else {d.aplica = "no"}
                d.tipo_pregunta = getPreguntaType(d.tipo_pregunta)
            })
             const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
             const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
             const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
             const data = new Blob([excelBuffer],{ type:fileType });
             FileSaver.saveAs(data, 'Excel Export' + fileExtension)
         }

    useEffect(()=>{
        axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{
            setDetalle(res.data)
        })
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then(
            function (response) {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then(
            function(response) {
            let n = response.data.filter(encuesta => encuesta.punto_encuesta !== null);
            setEncuestas(n)})
    },[])

    function getPreguntaType(x) {
        switch(x){
            case 'escala-tri':
                return "malo-excelente"
            case 'check':
                return "si/no"
            case 'audit':
                return "auditoria"
            default :
                return "pregunta abierta"
        }
    }
    
    function Tabulada(){
            let res = dataDetalle
            let giv = []
            res.forEach((r)=>{
                if(r.tipo_pregunta === "audit") {
                    if(r.respuesta === "ok") {r.valor = "100%"}
                    else {                        
                        r.valor = r.respuesta + "%"
                    }
                 giv.push(r)
                }
            })
          return giv
          //setDetalle([{id:1, imagen:"/profile_images/649b7dadace0f.jpeg"}])
          }
    
    const SetFilters = (data,e) => {
        e.preventDefault();
        if(!enc) {setConsulta(false);return;}
        let tab = Tabulada()
        let temp = tab
        temp =  tab.filter(el => {
            return sPuntos.some(p => {
                return sSecciones.some(s => {
                    return sPeriods.some(f => {
                    return p.id === el.punto_id && 
                    s.id === el.seccion_id && 
                    f.id === el.periodo_id && 
                    el.encuesta_id === enc.id
                   })
                })
            })
        })
        if(temp.length > 0) 
        {
         setDetalle(temp)
         setConsulta(true)
        }
        else {setConsulta(false)
            Toaster("danger", "la encuesta no contiene datos de auditoria")    
        }
        }
    
const handleEncuesta = (e) => {
    e.preventDefault()
    setSS([])
    let sec = []
    if(userData.permiso === "admin" || userData.permiso === "gerente")   
        { sec = secciones}
    else {
          sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
    if(enc) {
        setE(enc)
        setS(tem)
        }
    }
    return (<>
        <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={imgModal} onHide={hideImg}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <img className="image-modal" src={getValues().image} alt="no image found" />
            </Modal.Body>
      </Modal>
        <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onClick={handleEncuesta}>
                                    <option value={null}>Encuesta -</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}): null}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={Secciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                 options={puntos}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        <Row className='mt-3 m-1'>
                            <Button className="App-button m-2 mt-0" disabled={!consulta} onClick={exportToExcel}>Descargar Excel</Button>
                            <Button type="submit" className="App-button m-2 mt-0">Aplicar filtros <BiSearchAlt/></Button>
                        </Row> 
    </Form>
        <div className="content-body-alt mb-4">
            {consulta ? <Table className="App-Table p-1" striped responsive="md" hover size="sm">
                <thead>
                    <tr>
                        <th className="table-th">punto</th>
                        <th className="table-th">producto</th>
                        <th className="table-th">resultado</th>
                        <th className="table-th">resultado (%)</th>
                        <th className="table-th">comentario</th>
                        <th className="table-th">imagen</th>
                    </tr>
                </thead>
                <tbody>
                {dataDetalle.map(res => {
                    return <tr>
                            <td className='table-td'>{puntos.find(p => p.id === res.punto_id).nombre_sucursal}</td>
                            <td className='table-td'>{res.pregunta}</td>
                            <td className='table-td'>{res.respuesta}</td>
                            <td className="table-td">{res.valor}</td>
                            <td className='table-td'>{res.comentario}</td>
                            <td className='table-td blue-text' onClick={() => showImg(res)}>ver Imagen</td>
                        </tr>
                        })}
                </tbody>
            </Table> : null}
        </div>
    </div>
    </>)
}