import { Button, Table, Modal, Form, Toast, ToastContainer, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState, useContext, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { ColorPicker, useColor } from "react-color-palette";
import axios from "axios";
import ClientContext from "../../../Context/ClientContext";
import UserContext from '../../../Context/UserContext';
import {BiEditAlt, BiTrash, BiLinkAlt} from 'react-icons/bi'
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "react-color-palette/css";
 
export default function Encuestas() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const newLocation = useNavigate()
    const [loadBuffer, setBuffer] = useState(false)
    const {logData, setLog} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [encuestas, setEncuestas] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [sp, ssp] = useState(); //selected period.
    const [AllEncuestas, setAE] = useState([]);
    const [showTables, setShowTables] = useState(false);
    const [show, setShow] = useState(false);
    const [showEdit, setEditShow] = useState(false);
    const [showImport, setImport] = useState(false);
    const [showGen, setGen] = useState(false);
    const [viewElim, setElim] = useState(false);
    const [endText, setET] = useState(" Gracias por participar en la encuesta !")
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [_, forceUpdate] = useReducer(x => x + 1, 0);
    const [baseColor] = useColor("#DAD8F6")
    const [PrimaryColor, setPColor] = useColor("#DAD8F6")
    const [SecondaryColor, setSColor] = useColor("#4538D1")

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors },
      } = useForm({ mode: "onBlur" });
    
    const tooltip = (name) => { if(name !== undefined) { return <Tooltip id="tooltip"><strong>{name}</strong></Tooltip> } }

    const handleClose = () => {setShow(false);updateEL()}
    const handleShow = () => setShow(true);

    const handleGenshow = (encuesta) => {
        reset({
            id : encuesta.id,
            punto_encuesta: encuesta.punto_encuesta,
            nombre_encuesta: encuesta.nombre_encuesta,
            descripcion: encuesta.descripcion,
            estado: encuesta.estado,
            periodo: encuesta.periodo_encuesta,
            tipo_encuesta: encuesta.tipo_encuesta,
            chat_id : encuesta.chat_id
        })
        setGen(true)}
    const handleGenclose = () => {setGen(false)}

    const CloseImport = () => {setImport(false);updateEL()}
    const handleImport = () => {setImport(true)}
    
    const handleEditClose = () => {setEditShow(false);updateEL();}
    const handleEditShow = (encuesta) => {        
        setET(encuesta.mensaje_final)                
        if(encuesta.primary_color !== null) { setPColor(JSON.parse(encuesta.primary_color))}
        else {setPColor(baseColor)}
        if(encuesta.secondary_color !== null) {setPColor(JSON.parse(encuesta.primary_color))}
        else {setSColor(baseColor)}
        reset({            
            id : encuesta.id,
            punto_encuesta: encuesta.punto_encuesta,
            nombre_encuesta: encuesta.nombre_encuesta,
            descripcion: encuesta.descripcion,
            estado: encuesta.estado,
            tipo_encuesta: encuesta.tipo_encuesta,
            chat_id : encuesta.chat_id
        })
        setEditShow(true)
    }
    
    const showEliminar = (encuesta) => {
        setElim(true);
        reset({id : encuesta.id})
    }
    const hideEliminar = () => {setElim(false);updateEL()}

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");        
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}

    const CallModal = (datax, e) => {
        let jointContent = {}
        setBuffer(true)
        jointContent.periodo_id = datax.periodo_encuesta 
        datax.primary_color =  JSON.stringify(PrimaryColor)
        datax.secondary_color = JSON.stringify(SecondaryColor)
        datax.mensaje_final = endText;        
        e.preventDefault();
        axios.post(API_URL + '/encuesta', datax).then(
            function (response) {
                jointContent.encuesta_id = response.data.ID
                jointContent.client_id = logData.id
            axios.post(API_URL + '/joint', jointContent).then(res => {
                        Toaster("success", res.data.message)
                        setBuffer(false)
                        handleClose() 
                }).catch(err => {
                    Toaster("success", "Suscribete para más")
                    setBuffer(false)
                    handleClose()
                })
            })
    }

    const EditModal = (datax, e) => {
        e.preventDefault();
        setBuffer(true)
        let headers = setHeaders()        
        datax.primary_color =  JSON.stringify(PrimaryColor)
        datax.secondary_color = JSON.stringify(SecondaryColor)
        datax.mensaje_final = endText;                
        axios.post(API_URL + '/encuesta/update', datax, {headers}).then(
        function (response) {
            Toaster("success", response.data.message)
            setBuffer(false)
            handleEditClose()
        })       
        }

    const ImportEncuesta = (data,e) => {
        e.preventDefault();
        setBuffer(true)
        let jointContent = {}
        jointContent.periodo_id = Number(sp)
        jointContent.encuesta_id = data.id
        jointContent.client_id = logData.id
        axios.post(API_URL + '/joint', jointContent).then(res => {
                    Toaster("success", res.data.message)
                    setBuffer(false) 
                    CloseImport()
            })
    }

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    useEffect(() => {
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/encuesta/all', {params: {client_id:logData.id}}).then(res => {            
            if(typeof res.data === 'object') { let temp = Object.keys(res.data).map((key) => res.data[key]); setAE(temp)    }
            else { setAE(res.data) }                    
        })
        },[toast])

    const ChangeImport = e => {
        e.preventDefault();
        let t = Number(e.target.value)
        let enc = AllEncuestas.find(e => e.id === t)
        reset({
            id: enc.id,
            periodo_encuesta: sp,
            nombre_encuesta: enc.nombre_encuesta,
            estado: enc.estado,
            segmento: enc.segmento,
            descripcion: enc.descripcion,
            tipo_encuesta : enc.tipo_encuesta,
        })
    }

    const handlePeriodChange = e => {
        e.preventDefault();
        if (e.target.value != 0) {
            setShowTables(true);
            ssp(e.target.value)
        }
        else {setShowTables(false)};

        axios.get(API_URL + '/encuesta', { params: { periodo_encuesta:e.target.value } }).then(
            function(response) {
                setEncuestas(response.data)
            })
    }
    
    const Remove = () => {
        setBuffer(true)
        let pat = getValues()
        let headers = setHeaders()
        let data = encuestas.find(e => {return Number(e.id) === Number(pat.id)})     
        axios.get(API_URL + '/joint', { params: { encuesta_id:data.id } }).then(res => {            
            let temp = res.data.filter(j => {return Number(j.periodo_id) === Number(sp) && Number(j.encuesta_id === Number(data.id))})            
            axios.delete(API_URL + `/joint/${temp[0].id}`, {headers}).then(res => {
                Toaster("success", res.data.message)
                hideEliminar()
                setBuffer(false)
            })
        })
    }    

    const handleEncuesta = (e) => { 
        axios.get(API_URL + '/seccion', { params: { encuesta_id: e } }).then( function (response) { setSecciones(response.data) }) 
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => { setPuntos(res.data) })
    }
    function updateEL() {
        axios.get(API_URL + '/encuesta', { params: { periodo_encuesta:sp } }).then(
            function(response) {
                setEncuestas(response.data)
                reset({
                    id : null,
                    nombre_encuesta: null,
                    descripcion: null,
                    estado: 'activo',
                });
            })
    } 

    return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={showEdit} size="lg" onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Encuesta</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditModal)}>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre de la encuesta</Form.Label>
                        <Form.Control placeholder="Nombre" {...register("nombre_encuesta")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Tipo de encuesta</Form.Label>
                        <Form.Select {...register("tipo_encuesta")}> 
                            {logData && logData.servicio_encuesta === "activo" ? <option value="encuesta">encuesta</option>:null}
                            {logData && logData.servicio_audit === "activo" ? <option value="audit">auditoria</option>:null}
                            {logData && logData.servicio_mystery === "activo" ? <option value="mystery">mystery</option>:null }
                            {logData && logData.servicio_shop === "activo"? <option value="precios">precios</option>:null}
                            {logData && logData.servicio_desempeno === "activo"? <option value="desempeno">Desempeño Laboral</option>:null}
                            {logData && logData.servicio_climaLaboral === "activo"? <option value="clima">Clima Laboral</option>:null}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Canal de notificaciones</Form.Label>
                        <Form.Control {...register("chat_id")} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Color base</Form.Label>  
                        <ColorPicker height={50} hideInput={["rgb", "hsv", "hex"]} color={PrimaryColor} onChange={setPColor} onChangeComplete={(e) => {setValue("primary_color",e.hex)}}/>                        
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Color detalle</Form.Label>
                        <ColorPicker height={50} hideInput={["rgb", "hsv", "hex"]} color={SecondaryColor} onChange={setSColor} onChangeComplete={(e) => {setValue("secondary_color",e.hex)}}/>                        
                    </Form.Group>
                </Row>
                    {/*<Form.Group className="mb-2">
                        <Form.Label>Imagen de fondo</Form.Label>
                        <Form.Control type="file"  accept="image/*" {...register("background_image")}/>
                    </Form.Group>*/}
                    <Form.Group className="mb-2">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="..." {...register("descripcion")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                <p className="form-number-slim">
                    <Form.Label className="m-2 mt-2 mb-2">Mensaje de agradecimiento final</Form.Label>
                    <Form.Check defaultChecked={true} {...register("habilitar_mensaje")}/>
                </p>
                <ReactQuill  className="sub-banner-min mb-10" theme="snow" value={endText} onChange={setET} />                                                      
                <p className="form-number-slim">
                    <Form.Label className="m-2 mt-0 mb-2">URL de redireccion</Form.Label>
                    <Form.Check {...register("redireccion")}/>
                </p>
                <Form.Control className="mb-2" placeholder="" {...register("link")}/>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleEditClose}>
                Cerrar
            </Button>
            <Button type="submit" disabled={loadBuffer} className="App-button">
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Encuesta</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CallModal)}>
        <Form.Control type="hidden" value={sp} {...register("periodo_encuesta")}/>
        <Form.Control type="hidden" value={userData.subscription} {...register("subscription")} />
        <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
            <Modal.Body>
            <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre de la encuesta</Form.Label>
                        <Form.Control placeholder="Nombre" {...register("nombre_encuesta")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Tipo de encuesta</Form.Label>
                        <Form.Select {...register("tipo_encuesta")}> 
                            {logData && logData.servicio_encuesta === "activo" ? <option value="encuesta">encuesta</option>:null}
                            {logData && logData.servicio_audit === "activo" ? <option value="audit">auditoria</option>:null}
                            {logData && logData.servicio_mystery === "activo" ? <option value="mystery">mystery</option>:null }
                            {logData && logData.servicio_shop === "activo"? <option value="precios">precios</option>:null}
                            {logData && logData.servicio_desempeno === "activo"? <option value="desempeno">Desempeño Laboral</option>:null}
                            {logData && logData.servicio_climaLaboral === "activo"? <option value="clima">Clima Laboral</option>:null}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Canal de notificaciones</Form.Label>
                        <Form.Control {...register("chat_id")} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Color base</Form.Label>  
                        <ColorPicker height={50} hideInput={["rgb", "hsv", "hex"]} color={PrimaryColor} onChange={setPColor} onChangeComplete={(e) => {setValue("primary_color",e.hex)}}/>                        
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Color detalle</Form.Label>
                        <ColorPicker height={50} hideInput={["rgb", "hsv", "hex"]} color={SecondaryColor} onChange={setSColor} onChangeComplete={(e) => {setValue("secondary_color",e.hex)}}/>                        
                    </Form.Group>
                </Row>
                    {/*<Form.Group className="mb-2">
                        <Form.Label>Imagen de fondo</Form.Label>
                        <Form.Control type="file"  accept="image/*" {...register("background_image")}/>
                    </Form.Group>*/}
                    <Form.Group className="mb-2">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="..." {...register("descripcion")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                <p className="form-number-slim">
                    <Form.Label className="m-2 mt-2 mb-2">Mensaje de agradecimiento final</Form.Label>
                    <Form.Check defaultChecked={true} {...register("habilitar_mensaje")}/>
                </p>
                <ReactQuill  className="sub-banner-min mb-10" theme="snow" value={endText} onChange={setET} />                                                      
                <p className="form-number-slim">
                    <Form.Label className="m-2 mt-0 mb-2">URL de redireccion</Form.Label>
                    <Form.Check {...register("redireccion")}/>
                </p>
                <Form.Control className="mb-2" placeholder="" {...register("link")}/>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={loadBuffer} > 
                Crear
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={showGen} onHide={handleGenclose}>
        <Modal.Header closeButton>
          <Modal.Title>Generar link para clientes</Modal.Title>
        </Modal.Header>
        <Form>        
            <Modal.Body>                          
                    <Form.Select className="mt-0 mb-2" onChange={(e) => {setValue("Sucursal_id", e.target.value);}}>
                        <option value={0}>Seleccionar Sucursal...</option>
                        {puntos.map((periodo) => <option value={periodo.id}>{periodo.nombre_sucursal}</option>)}
                    </Form.Select>                     
                    <Form.Select className="mt-0 mb-2" onChange={(e) => {setValue("Seccion_id", e.target.value); forceUpdate()}}>
                        <option value={0}>Seleccionar Sección...</option>
                        {secciones.map((periodo) => <option value={periodo.id}>{periodo.nombre_seccion}</option>)}
                    </Form.Select>                
            </Modal.Body>
            <Modal.Footer>        
                { getValues("Seccion_id") > 0 && getValues("Sucursal_id") > 0 ? <Form.Control readOnly onClick={() => {navigator.clipboard.writeText("https://app.medicionanalytic.com/encuesta/"+sp+"/"+getValues("id")+"/"+getValues("Seccion_id")+"/"+getValues("Sucursal_id")); Toaster("success", "Link copiado")}} value={"https://app.medicionanalytic.com/encuesta/"+sp+"/"+getValues("id")+"/"+getValues("Seccion_id")+"/"+getValues("Sucursal_id")} /> : null}                
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={showImport} onHide={CloseImport}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Encuesta</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(ImportEncuesta)}>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Encuestas para importar</Form.Label>                
                        <Form.Select onChange={ChangeImport}>
                            <option value={null}>Sin Encuesta</option>
                            {AllEncuestas.map(ae => {
                                return <option value={ae.id}>{ae.nombre_encuesta}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={CloseImport}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={loadBuffer} >
                Importar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={viewElim} onHide={hideEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Encuesta</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" className="App-button" disabled={loadBuffer} onClick={() => Remove()}>Eliminar</Button>
            </Modal.Footer>
    </Modal>
        <div className="content-container">
            <div className="content-header"><h4>Periodos</h4></div>
            <div className="content-body pt-2">
                <Form style={{width:"98%"}}>
                    <Form.Select className="m-2 mt-0" onChange={handlePeriodChange}>
                        <option value={0}>seleccionar periodo...</option>
                        {periodos.map((periodo) => <option value={periodo.id}>{periodo.nombre_periodo}</option>)}
                    </Form.Select>
                </Form>
               {showTables ? <>
                <div className="content-header mb-2"><h4>Encuestas</h4></div>
                <Button className="m-2 App-button" onClick={handleShow}> Nueva encuesta </Button>
                <Button className="m-2 App-button" onClick={handleImport}> Importar encuesta </Button>
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>    
                            <th className="table-th">Encuesta</th>
                            <th className="table-th extended">Descripción</th>                        
                            <th className="table-th">Estado</th>
                            <th className="table-th">Link/Enlace</th>                  
                            <th></th>      
                        </tr>
                    </thead>
                    <tbody>
                            {encuestas.map((encuesta) => 
                               <tr>                    
                                    <td>{encuesta.nombre_encuesta}</td>
                                    <td>{encuesta.descripcion}</td>                                    
                                    <td>{encuesta.estado}</td>
                                    <td><a href={"https://app.medicionanalytic.com/encuesta/"+sp+"/"+encuesta.id}>https://app.medicionanalytic.com/encuesta/{sp+'/'+encuesta.id}</a></td>
                                    <td className="clickable-end">
                                        <OverlayTrigger placement="bottom" overlay={tooltip("Editar encuesta")}> 
                                            <td className="clickable"><BiEditAlt onClick={() => handleEditShow(encuesta)}/></td>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip("Eliminar encuesta")}>
                                            <td className="clickable"><BiTrash onClick={() => showEliminar(encuesta)}/></td>     
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip("Generar Link para clientes")}>
                                            <td className="clickable"><BiLinkAlt onClick={() => {handleEncuesta(encuesta.id); handleGenshow(encuesta)} }/></td>                          
                                        </OverlayTrigger>     
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
                </>:null}
            </div>
            <div className="flex-between">
                <Button className="App-button" onClick={() => {newLocation("../periodos")}}>Atrás</Button>
                <Button className="App-button" onClick={() => {newLocation("../secciones")}}>Siguiente</Button>
            </div>
        </div>
    </>)
}