import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col, OverlayTrigger, Tooltip, Nav, Tab, Accordion} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import { BarLoader } from "react-spinners";
import ParametrosContext from "../../../Context/ParametrosContext";
import {format } from 'date-fns';
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";


export default function ReporteEvaluacion() {
    const API_URL = 'https://api.medicionanalytic.com/api'    
    const {paramData} = useContext(ParametrosContext);
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [reportes, setRep] = useState([])
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [videos, setVideos] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const [segmentos, setSegmentos] = useState([])

    const [preguntas, setPreg] = useState([])
    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])
    const [SSec, setSSec] = useState([])    
    const [PS, setPS] = useState([])
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])
    const [loader, setLoad] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

      const tooltip = (name) => {
        if(name !== undefined) {
            return <Tooltip id="tooltip">
            <strong>{name}</strong>
        </Tooltip>
        }
      }

    useEffect(()=>{                
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then((response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
                      res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
                      if(logData.id === 49) {setPuntos(res.data)}
                      else {setSuc(res.data)}                
                  })
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then((response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })                
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then((response) => {
            let n;
            let temp;
            if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
            else { temp = response.data }
            if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null);}
            else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null);}
            setBaseEncuestas(n)
        })
        axios.get(API_URL + '/pregunta/all', {params: {client_id: logData.id}}).then((res)=>{setPreg(res.data)})
        axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"pregunta" } }).then((response) =>{
            let temp = response.data
            temp.forEach(el => {el.label = el.nombre_segmento; el.value = el.id;})
            setSegmentos(temp.filter(t => { return t.aplica_calculo === 1}))
        })
    },[])

    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {        
        let Document = dataFiltered
        Document.sort((a, b) => a.orden > b.orden ? 1 : -1)
        .sort((a, b) => a.Reporte_id > b.Reporte_id ? 1 : -1)
        .forEach((d) => {
            delete d.tipo_pregunta;
            delete d.punto_id;
            delete d.periodo_id;            
            d.Pregunta = preguntas.find(p => {return Number(p.id) === Number(d.Pregunta_id)}) ? preguntas.find(p => {return Number(p.id) === Number(d.Pregunta_id)}).titulo : d.Pregunta
            d.Orden = preguntas.find(p => {return Number(p.id) === Number(d.Pregunta_id)}) ? preguntas.find(p => {return Number(p.id) === Number(d.Pregunta_id)}).orden : ""
            d.Nombre_video = videos.find(v => {return Number(v.id) === Number(d.Video_id)}) ? videos.find(v => {return Number(v.id) === Number(d.Video_id)}).nombre_video : ""
            d.Segmento = preguntas.find(p => {return Number(p.id) === Number(d.Pregunta_id)}) ? segmentos.find(s => {return Number(s.id) === Number(preguntas.find(p => {return Number(p.id) === Number(d.Pregunta_id)}).segmento)}).nombre_segmento : ""
        })  

        const ws = XLSX.utils.json_to_sheet(Document.sort((a, b) => a.Orden > b.Orden ? 1 : -1)) //pass jason data        
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });        
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
        
    }
    const exportTable = async () => {
        let objec = {Seccion:"", Preguntas:"",Segmento:""}                
        let reortes = []
        dataFiltered.forEach(res => {
            let indep = reortes.findIndex(rep => rep.Reporte_id === res.Reporte_id)
            if(indep === -1) { reortes.push(res)}
        })                                            
        let test = preguntas.filter(p => {return sSecciones.some(s => {return Number(p.seccion_id) === Number(s.id) })}).map((p,i) => {                                
            let temp = Object.assign({}, objec);            
            temp.Seccion = sSecciones.find(s => {return Number(s.id) === Number(p.seccion_id)}) ? sSecciones.find(s => {return Number(s.id) === Number(p.seccion_id)}).nombre_seccion : ""
            temp.Preguntas = p.titulo            
            temp.Segmento = p.segmento ? segmentos.find(s => {return Number(s.id) === Number(p.segmento)}).nombre_segmento : ""
            temp.Orden = p.orden            
            reortes.forEach(pn => {                                       
                Object.assign(temp, { 
                    [pn.Video_id ? videos.find(v => {return Number(v.id) === Number(pn.Video_id)}).nombre_video : pn.Reporte_id]: 
                    dataFiltered.find(d => {return Number(d.Pregunta_id) === Number(p.id) && Number(d.Reporte_id) === Number(pn.Reporte_id)}) ? 
                    dataFiltered.find(d => {return Number(d.Pregunta_id) === Number(p.id) && Number(d.Reporte_id) === Number(pn.Reporte_id)}).Value : "NaN"});
            })            
            return temp
        })                            
        const ws = XLSX.utils.json_to_sheet(test.sort((a, b) => a.Orden > b.Orden ? 1 : -1)) //pass jason data
        var wscols = [
            {wch:20},
            {wch:25},
            {wch:15},
            {wch:5},            
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:20},
        ];      
        Object.entries(ws).forEach(t => {               
        if(t[0].includes("1 ")||t[0].includes("D")){return}
        if(isNaN(Number(t[1].v))){return}
        if(Number(t[1].v) < paramData.values.amarillo) {t[1].s = {
            fill: { patternType: "solid", bgColor: { rgb:"f08080"}, fgColor: { rgb:"f08080"} },
            alignment: {vertical:"center", horizontal:"center"}
            }}
            else if(Number(t[1].v) < paramData.values.verde) {t[1].s = {
            fill: { patternType: "solid", bgColor: { rgb:"ffff00"} ,fgColor: { rgb:"ffff00"} },
            alignment: {vertical:"center", horizontal:"center"}
            }}
            else if(Number(t[1].v) >= paramData.values.verde) {t[1].s = {
            fill: { patternType: "solid", bgColor: { rgb:"9acd32"},fgColor: { rgb:"9acd32"} },
            alignment: {vertical:"center", horizontal:"center"}
            }}
        })                  
        ws['!cols'] = wscols;                        
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });        
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    function getOccurrence(array, value) {return respuestas.filter((v) => (v.reporte_id === value)).length;}

    
    function Tabulada(){        
        let sum = []
        let res = respuestas
        if(userData.permiso === "admin") { res = respuestas }
        else {
            res = respuestas.filter(el => {
                return respuestas.some(r=>{
                    return userData.puntos.split(",").indexOf(r.punto_id.toString()) > -1 || userData.secciones.split(",").indexOf(r.seccion_id.toString()) >-1 })
            })
        }        
        res.filter(r => r.encuesta_id === enc.id)
        .forEach((r)=>{            
            if(r.tipo_pregunta === 'escala-tri') {
                if(r.respuesta === 'malo') { r.Value = 0; sum.push(r) }
                if(r.respuesta === 'regular') { r.Value =50; sum.push(r) }
                if(r.respuesta === 'excelente') { r.Value =100;sum.push(r) }
              }
              if(r.tipo_pregunta === "escala") { r.Value =(r.respuesta*100)/r.valor_max; sum.push(r) }
              if(r.tipo_pregunta === "thumbs") {
                  if(r.respuesta === "si") { r.Value = 100; sum.push(r) }
                  else if(r.respuesta === "no"){ r.Value = 0; sum.push(r) }
                  else { r.Value ="na"; sum.push(r) }
              }
              if(r.tipo_pregunta === "stars"){ r.Value =(r.respuesta*100)/5; sum.push(r) }
              if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){ r.Value = (r.respuesta*100)/10; sum.push(r) }
              if(r.tipo_pregunta === "smiles"){ r.Value = (r.respuesta*100)/5; sum.push(r) }
        })
        return sum;
      }

    function showData(filtered) {
        let helper = []
        filtered.forEach(v => {            
            const bat = getOccurrence(filtered, v.reporte_id)
            if (bat > 1) {
            const indet = helper.findIndex(o => o.reporte_id === v.reporte_id && o.pregunta_id === v.pregunta_id)            
            if(indet === -1) {
                let temp = filtered.filter((q) => (q.reporte_id === v.reporte_id && q.pregunta_id === v.pregunta_id))
                var result = {
                    Reporte_id: v.reporte_id,
                    Encuesta_id: v.encuesta_id,
                    Punto_id : v.punto_id,
                    Nombre_sucursal: Sucursales.find(p => p.id === v.punto_id).nombre_sucursal,
                    Periodo_id: v.periodo_id,
                    Seccion_id : v.seccion_id,
                    Pregunta_id : v.pregunta_id,
                    Pregunta : v.pregunta,
                    Video_id: v.video_id,
                    Value : (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/temp.length).toFixed(2) }
                helper.push(result)
             }
           }
           else { 
            var result = {
            Reporte_id: v.reporte_id,
            Encuesta_id: v.encuesta_id,
            Punto_id : v.punto_id,
            Nombre_sucursal: Sucursales.find(p => p.id === v.punto_id).nombre_sucursal,
            Periodo_id: v.periodo_id,
            Seccion_id : v.seccion_id,
            Pregunta : v.pregunta,
            Pregunta_id : v.pregunta_id,
            Video_id: v.video_id,
            Value : v.Value }
            helper.push(result)}
        })        
        setDF(helper)        
        setConsulta(true)        
    }
    
    const SetFilters = (data,e) => {
        e.preventDefault();
        let tab = Tabulada()
        if(!enc) {setConsulta(false);return;}
        let temp = []
        temp =  tab.filter(el => {
            return sPuntos.some(p => {
                return sSecciones.some(s => {
                   return sPeriods.some(f => {
                       return p.id === el.punto_id && 
                    s.id === el.seccion_id && 
                    f.id === el.periodo_id && 
                    el.encuesta_id === enc.id
                })
            })
        })
    })    
    setPS(sPeriods);
    setSSec(sSecciones);    
    showData(temp)            
    }

    function getClass(val) {        
        let x = Number(val)
        if(isNaN(x)|| val === null){return "Fondo-Blank-E"}
        if(x < paramData.values.amarillo) {return "Fondo-R-E"}
        if(x < paramData.values.verde) {return "Fondo-A-E"}
        if(x >= paramData.values.verde) {return "Fondo-V-E"}
    }
    
    const handleEncuesta = (e) => {
        setLoad(true)
        e.preventDefault()
        setSS([])
        let sec = []        
        if(userData.permiso === "admin")   
        { sec = secciones}
        else {
          sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
        if(enc) {
            axios.get(API_URL+'/respuesta', {params: { encuesta_id:Number(e.target.value),periodo_id: sPeriods }}).then(res=>{
                let temp = res.data             
                let temp2 = [] 
                axios.get(API_URL + '/video', { params: { client_id: logData.id} }).then((response) => {                 
                    let videos = response.data.filter(v => {return v.estado !== "inactivo"})                                
                    if(userData.permiso === "admin" || userData.permiso === "gerente") { temp2 = temp; setVideos(response.data); }
                    else {
                        let tetemp = temp.filter(el=> { return userData.encuestas.split(",").some(s => {return Number(el.encuesta_id) === Number(s) }) })                   
                        temp2 = tetemp.filter(p => { return videos.some(v => {return (p.video_id === v.id || p.video_id === null)})}); 
                        setVideos(videos);
                    }
                    setR(temp2); setLoad(false)
                })
            })
            axios.get(API_URL+'/reporte', {params:{encuesta:Number(e.target.value), periodo_id: sPeriods}}).then(res =>{setRep(res.data)})
            setE(enc)
            setS(tem)
        }
    }
    
    const getDateshort = (report) => {        
        if(reportes.find(r => r.id === report) !== undefined){                     
            const formattedDate = format(reportes.find(r => r.id === report).created_at, 'dd/MM/H:mm');            
            return formattedDate
        }
    }
    const handleCity = (e) => {  
        e.preventDefault()       
        setSPtos([])        
        let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
        setSuc(sucursales)
    }
    useEffect (()=> {    
        setEncuestas([])                  
        if(sPeriods.length > 0) {        
          let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) })})          
          let temp2 = Basencuestas.filter(e => { return temp.some(j => {return (Number(j.encuesta_id) === Number(e.id)) }) })      
          setEncuestas(temp2)
        }
      }, [sPeriods])  
return (<>
    <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onChange={handleEncuesta} >
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                            {logData.id === 49 ? 
                            <Form.Select onChange={handleCity} >
                                <option value={null}>Sin Ciudad Seleccionada</option>
                                {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                            </Form.Select>
                            : <MultiSelect
                            overrideStrings={{
                               "allItemsAreSelected": "Todo Seleccionado.",
                               "clearSearch": "Limpiar busqueda",
                               "clearSelected": "Limpiar Seleccion",
                               "noOptions": "Sin opciones",
                               "search": "Buscar",
                               "selectAll": "Todos",
                               "selectAllFiltered": "Todos (Filtrado)",
                               "selectSomeItems": "Seleccionar Secciones",
                               "create": "Create",}
                             }
                            options={Secciones}
                            value={sSecciones}
                            onChange={setSS}
                            labelledBy="Select"
                           />}
                            </Col>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                 options={userData.permiso === "admin" ?
                                 Sucursales   :
                                 Sucursales.filter(el => {return  userData.puntos.split(",").indexOf(el.id.toString()) > -1})}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        {logData.id === 49 ? <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={Secciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>: null}
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0" disabled = {loader}>
                                {loader ? <BarLoader className="m-2" color="#FFF" width={100} />:<>Aplicar filtros <BiSearchAlt/></> }
                            </Button>
                        </Row>
    </Form>
    {consulta ? 
    <div className="mb-4">
        <Row className="row-excel-button">
                        <h3 className="p-2" style={{width:'50vw'}}>Resultados de reportes por evaluacion</h3>
                        <Accordion style={{width:'unset !important'}}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="App-button">Descargar</Accordion.Header>
                                <Accordion.Body>                                
                                    {enc && enc.tipo_encuesta === "mystery" ? <Nav.Item><Nav.Link className="p-2 pt-1 pb-1" eventKey="contactos" onClick={exportTable} >Tabla</Nav.Link></Nav.Item> : null}
                                    <Nav.Item><Nav.Link className="p-2 pt-1 pb-1" eventKey="data" onClick={exportToExcel} >Data</Nav.Link></Nav.Item>              
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>                                                          
                    </Row>                
        <Table className="App-table" responsive striped hover>
            <thead>
                        <tr>
                            <th className="table-th sticky-col first-col" rowSpan={3}>Seccion</th>
                            <th className="table-th extended sticky-col second-col" rowSpan={3}>Preguntas</th>      
                            <th className="table-th sticky-col third-col" rowSpan={3}>segmento</th>
                            {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                            .map(period => {return <th className="table-th">{period.nombre_corto}</th>})}
                        </tr>
                        <tr>                   
                        {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                        .map(per => {                            
                            let untos = []
                            let sucs = []                            
                            dataFiltered.forEach(p => { if(sucs.findIndex(rep => rep.Reporte_id === p.Reporte_id) === -1) { sucs.push(p) } })
                        return <th className="p-0">{
                                dataFiltered.filter(res => res.Periodo_id === per.id)
                                .sort((a, b) => a.Punto_id > b.Punto_id ? 1 : -1)
                                .map(res => {                                                                        
                                    let reortes = []
                                    const inde = untos.findIndex(rep => rep.Punto_id === res.Punto_id)                                    
                                    if(inde === -1) { untos.push(res)                                                                                          
                                        return <th>
                                        <tr className=""><th className="table-th" style={{minWidth: (sucs.filter(s => s.Punto_id === res.Punto_id && s.Periodo_id === res.Periodo_id).length)*6+'rem'}}>{res.Nombre_sucursal}</th></tr>
                                        <tr className=""><th>{   
                                         dataFiltered.filter(ress => ress.Punto_id === res.Punto_id && ress.Periodo_id === res.Periodo_id)
                                         .sort((a,b) => (a.Reporte_id < b.Reporte_id) ? -1 : 1)
                                        .map( ress => {                                                                                    
                                        const indess = reortes.findIndex(rep => rep.Reporte_id === ress.Reporte_id)
                                        if(indess === -1) { reortes.push(ress)                                                                                                
                                                return <OverlayTrigger placement="bottom" overlay={videos.filter(v => {return v.id === ress.Video_id}).length > 0 ? tooltip(videos.find(v => {return v.id === ress.Video_id}).nombre_video) : tooltip(getDateshort(ress.Reporte_id))}>      
                                                <th className="table-th">{videos.filter(v => {return v.id === ress.Video_id}).length > 0 ? videos.find(v => {return v.id === ress.Video_id}).nombre_video : getDateshort(ress.Reporte_id)}</th>
                                                </OverlayTrigger>
                                            }
                                        })
                                    }</th></tr>
                                        </th> 
                                    }                                    
                                })
                            }</th>                                   
                        })}
                        </tr>                  
            </thead>
            <tbody>                                                        
                {SSec.map(s => {
                    return preguntas.filter(p => p.seccion_id === s.id && p.tipo_pregunta !== "texto" && p.tipo_pregunta !== "comentario" && p.tipo_pregunta !== "info" && p.tipo_pregunta !== "select" && p.tipo_pregunta !== "selectm" && p.tipo_pregunta !== "load")
                    .sort((a,b) => (a.orden < b.orden) ? -1 : 1)
                    .map((dt, index) => {
                        return <tr>
                            <td className="sticky-col first-col-content">{index === 0 ? s.nombre_seccion : ""}</td>
                            <td className="sticky-col second-col-content expanded">{dt.titulo}</td>
                            <td className="sticky-col third-col-content">{dt.segmento && segmentos.find(s => {return Number(s.id) === Number(dt.segmento)}) ? segmentos.find(s => {return Number(s.id) === Number(dt.segmento)}).nombre_segmento : ""}</td>
                            {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                            .map(per => {
                                let untos = []
                                let sucs = []                            
                                dataFiltered.forEach(p => { if(sucs.findIndex(rep => rep.Reporte_id === p.Reporte_id) === -1) { sucs.push(p) } })
                                return <th className="p-0">{
                                    dataFiltered.filter(res => res.Periodo_id === per.id)
                                    .sort((a, b) => a.Punto_id > b.Punto_id ? 1 : -1)
                                    .map(res => {                                                                        
                                        let reortes = []
                                        const inde = untos.findIndex(rep => rep.Punto_id === res.Punto_id)                                    
                                        if(inde === -1) { 
                                        untos.push(res)                                                                                                                                  
                                        return <th>                                        
                                        {   
                                         dataFiltered.filter(ress => ress.Punto_id === res.Punto_id && ress.Periodo_id === per.id && ress.Pregunta_id === dt.id)
                                        .sort((a,b) => (a.Reporte_id < b.Reporte_id) ? -1 : 1)
                                        .map( ress => {                                                                                    
                                            const indess = reortes.findIndex(rep => rep.Reporte_id === ress.Reporte_id)
                                            if(indess === -1) { reortes.push(ress)                                                                                                
                                                return <td className={getClass(ress.Value)}>{ress.Value + "%"}</td>                                
                                            }
                                        })
                                    }</th> 
                                    }                                    
                                })
                            }</th>   
                            })}
                            </tr>
                        })
                    })
                }
            </tbody>
        </Table>
        </div>: null}
    </div>
    </>)
}